import { createQueryKeys } from '@lukemorales/query-key-factory'
import { APIAxiosInstance as LegacyAPIAxiosInstance } from '@core/utils/legacy-api-axios-instance'
import { APIAxiosInstance } from '@core/utils/api-axios-instance'
import { RequestTypes } from '@core/react-query/features/permits/requestTypes'
import { PageResponse } from '@core/entities/pagination'
import { IPermitBase } from '@core/entities/permit/PermitBase'
import { createQueryKeyString } from '@core/react-query/queryParams'

export const permitsKeys = createQueryKeys('permits', {
  allPermits: ({
    organisationId,
    projectIds,
    dateRange,
    startsOnRange,
    createdOnRange,
    page,
    size,
    ...filters
  }: RequestTypes['useAllOrganisationPermits']) => {
    return {
      queryKey: [
        organisationId,
        projectIds,
        { ...filters, ...dateRange, ...startsOnRange, ...createdOnRange },
        page,
        size,
      ],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          page: page ? page : pageParam,
          size,
          organisationId,
          projectIds,
          ...dateRange,
          startsOnRangeStartTime: startsOnRange?.startTime,
          startsOnRangeEndTime: startsOnRange?.endTime,
          createdOnRangeStartTime: createdOnRange?.startTime,
          createdOnRangeEndTime: createdOnRange?.endTime,
          ...filters,
        })

        const { data } = await LegacyAPIAxiosInstance.get(
          `permits/all-permits${queryParams}`,
        )

        return data as PageResponse<IPermitBase>
      },
    }
  },
  allPermitsPage: ({
    organisationId,
    projectIds,
    dateRange,
    startDate,
    createdDate,
    page,
    size,
    ...filters
  }: RequestTypes['useAllOrganisationPermits']) => {
    return {
      queryKey: [
        organisationId,
        projectIds,
        dateRange,
        startDate,
        createdDate,
        filters,
        page,
        size,
      ],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          page,
          size,
          organisationId,
          projectIds,
          ...dateRange,
          ...startDate,
          ...createdDate,
          ...filters,
        })

        const { data } = await APIAxiosInstance.get(
          `permits/all-permits${queryParams}`,
        )

        return data as PageResponse<IPermitBase>
      },
    }
  },
  drafts: ({
    organisationId,
    projectIds,
    page,
    size,
  }: RequestTypes['getDrafts']) => {
    return {
      queryKey: [organisationId, projectIds, page, size],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          page: pageParam,
          size,
          organisationId,
          projectIds,
        })
        const { data } = await APIAxiosInstance.get(
          `permits/drafts${queryParams}`,
        )

        return data
      },
    }
  },
  draftsPage: ({
    organisationId,
    projectIds,
    page,
    size,
  }: RequestTypes['getDrafts']) => {
    return {
      queryKey: [organisationId, projectIds, page, size],
      queryFn: async () => {
        const queryParams = createQueryKeyString({
          page,
          size,
          organisationId,
          projectIds,
        })
        const { data } = await APIAxiosInstance.get(
          `permits/drafts${queryParams}`,
        )

        return data
      },
    }
  },
  getRecentParticipants: (payload: RequestTypes['getPermitParticipants']) => {
    return {
      queryKey: [payload.term, payload.projectId, payload.authGroupsIds],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(
          `permits/participants/recent`,
          {
            params: payload,
            paramsSerializer: { indexes: null },
          },
        )

        return data.participants
      },
    }
  },
  projectMapPermits: ({
    projectId,
    permitStatuses,
    startTime,
    endTime,
    templateIds,
  }: RequestTypes['getProjectMapPermits']) => {
    return {
      queryKey: [projectId, permitStatuses, startTime, endTime, templateIds],
      queryFn: async ({ pageParam = 1 }) => {
        const queryParams = createQueryKeyString({
          projectId,
          permitStatuses: permitStatuses?.length ? permitStatuses : null,
          startTime,
          endTime,
          templateIds: templateIds?.length ? templateIds : null,
        })
        const { data } = await APIAxiosInstance.get(`permits/map${queryParams}`)

        return data
      },
    }
  },
  getPermitReport: ({
    reportingType,
    organisationId,
    projectIds,
    ...filters
  }: RequestTypes['getPermitReport']) => {
    return {
      queryKey: [reportingType, organisationId, projectIds, filters],
      queryFn: async () => {
        const queryString = createQueryKeyString({
          organisationId,
          projectIds,
          ...filters,
        })
        const { data } = await APIAxiosInstance.get(
          `reporting/permits/${reportingType}${queryString}`,
        )

        return data
      },
    }
  },
})
