import React from 'react'
import { DragIndicator } from '@mui/icons-material'
import { Box } from '@fundamentals'
import { useSortable } from '@dnd-kit/sortable'
import { Tooltip } from '@common/material'
import { SxProps, Theme } from '@mui/material'

type ComponentType = {
  id: string | number
  disabled?: boolean
  sx?: SxProps<Theme>
}

export const DraggableIcon: React.FC<ComponentType> = ({
  id,
  disabled,
  sx,
}) => {
  const { attributes, listeners } = useSortable({ id })

  if (disabled) {
    return (
      <Tooltip title='Cannot drag with unsaved changes'>
        <DragIndicator sx={{ opacity: 0.5, cursor: 'not-allowed', ...sx }} />
      </Tooltip>
    )
  }

  return (
    <Box {...attributes} {...listeners} sx={{ cursor: 'grab', ...sx }}>
      <DragIndicator sx={{ opacity: 0.5 }} />
    </Box>
  )
}
