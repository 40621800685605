import { localizedStrings } from '@core/strings'
import React, { useMemo } from 'react'
import { Box, Flex, Text } from 'webapp/components/common/fundamentals'
import { useModal } from 'webapp/components/common/GlobalModal'
import { Button, Card, Divider } from '@common/material'
import { ParticipantLogPermitModal } from './modals/ParticipantLogPermitModal'
import { PermitDetailsSidebarProps } from './types'
import { CardContent } from '@mui/material'
import { useGetProjectPlan } from '@core/react-query/features/projects/plans'
import useFeatureFlags from '@core/providers/useFeatureFlags'
import { Collapsible } from '@common/Collapsible'
import { Section } from '@common/Section'
import moment from 'moment'
import { ProjectPlanPermitsModal } from '../projectPlan/modals/ProjectPlanPermitsModal'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { PermitApprovalWrapper } from '@core/entities/permit/PermitApprovalWrapper'
import LaunchIcon from '@mui/icons-material/Launch'
import { PageUrls } from '@core/page-urls'
import { ReviewPermitCard } from './permitDetailsHeader/ReviewPermitCard'
import { RequestedChangesCard } from './permitDetailsHeader/RequestedChangesCard'
import { TransferPermitCard } from './permitDetailsHeader/TransferPermitCard'
import { ReceivePermitCard } from './permitDetailsHeader/ReceivePermitCard'
import { ReviewExtensionRequestPermitCard } from './permitDetailsHeader/ReviewExtensionRequestPermitCard'
import { FinalSignoffPermitCard } from './permitDetailsHeader/FinalSignoffPermitCard'
import { PermitSuspensionCard } from './permitDetailsHeader/PermitSuspensionCard'
import { PermitSuspensionReasonCard } from './permitDetailsHeader/PermitSuspensionReasonCard'
import { PostPermitChecksCard } from './permitDetailsHeader/PostPermitChecksCard'
import { SimpleMapModal } from '@modules/geolocation'
import { useRouter } from 'next/router'
import { ProjectPlanPermitModalLegacy } from '@modules/projectPlan/modals/ProjectPlanPermitModalLegacy'

export const PermitDetailsSidebar: React.FC<PermitDetailsSidebarProps> = ({
  organisationId,
  projectId,
  permit,
}) => {
  const { flags } = useFeatureFlags([
    'web_project_plans',
    'web_plan_view_skia_refactor',
  ])
  const { showModal } = useModal()
  const router = useRouter()

  const { locationNode, locationPlan, selectedAreas } =
    permit?.projectLocations?.areas?.[0] || {}

  const { data: plan } = useGetProjectPlan(
    {
      projectId: permit?.project?.id,
      nodeId: locationNode?.id,
      planId: locationPlan?.id,
    },
    {
      enabled:
        !!locationNode?.id &&
        !!locationPlan?.id &&
        !!permit?.project?.id &&
        !!flags.web_project_plans,
      refetchOnMount: false,
    },
  )

  const handlePersonClick = (participant) => {
    showModal(
      <ParticipantLogPermitModal
        participantDetail={participant}
        permit={permit}
      />,
    )
  }

  const reviews: PermitApprovalWrapper[] = useMemo(() => {
    return permit?.getReviews() || []
  }, [permit])

  const permitHolderParticipant = permit.participants.find(
    ({ userId }) => userId === permit.permitHolder.id,
  )

  return (
    <Box
      pl={3}
      pb={3}
      width='23rem'
      position='sticky'
      top={174.2}
      maxHeight='calc(100vh - 174.2px)'
      overflow='auto'
    >
      {permit && (
        <>
          <ReviewPermitCard
            organisationId={organisationId}
            projectId={projectId}
            permit={permit}
          />

          <RequestedChangesCard
            organisationId={organisationId}
            projectId={projectId}
            permit={permit}
          />

          <TransferPermitCard permit={permit} />

          <ReceivePermitCard permit={permit} projectId={projectId} />

          <ReviewExtensionRequestPermitCard permit={permit} />

          <FinalSignoffPermitCard
            permit={permit}
            organisationId={organisationId}
            projectId={projectId}
          />

          <PostPermitChecksCard
            permit={permit}
            organisationId={organisationId}
            projectId={projectId}
          />

          <PermitSuspensionCard permit={permit} />

          <PermitSuspensionReasonCard permit={permit} />
        </>
      )}

      <Card>
        <CardContent>
          <Text
            sx={{ fontSize: '16px' }}
            variant='h6'
            color='text.primary'
            gutterBottom
          >
            General Information
          </Text>
          <Divider />

          <Section label={localizedStrings.summary} mt={2}>
            <Text data-test='permit-summary' showTooltip>
              {permit?.summary}
            </Text>
          </Section>

          <Section label={localizedStrings.project} mt={2}>
            <Text
              showTooltip
              color='primary.main'
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                router.push(
                  PageUrls.project(organisationId, permit?.project?.id),
                )
              }}
            >
              {permit?.project?.name}
            </Text>
          </Section>

          <Section label={localizedStrings.location} mt={2}>
            <Text showTooltip>{permit?.location}</Text>
          </Section>

          {permit?.projectLocations?.areas?.length > 0 &&
            flags.web_project_plans && (
              <Section label={localizedStrings.zones} mt={2}>
                <Text
                  color='primary.main'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (
                      !permit?.projectLocations?.locations?.[0]?.locationName ||
                      !locationNode?.id ||
                      !plan?.id ||
                      !permit?.project?.id
                    )
                      return null

                    if (flags.web_plan_view_skia_refactor) {
                      showModal(
                        <ProjectPlanPermitsModal
                          nodeId={locationNode.id}
                          projectId={permit.project.id}
                          planId={plan.id}
                          nodePath={
                            permit.projectLocations.locations[0].locationName
                          }
                          initialSelectedAreas={selectedAreas?.map(
                            (area) => area.uuid,
                          )}
                          permitId={permit.id}
                          plan={plan}
                          readOnly
                        />,
                      )
                    } else {
                      showModal(
                        <ProjectPlanPermitModalLegacy
                          nodeId={locationNode.id}
                          projectId={permit.project.id}
                          planId={plan.id}
                          nodePath={
                            permit.projectLocations.locations[0].locationName
                          }
                          initialSelectedAreas={selectedAreas?.map(
                            (area) => area.uuid,
                          )}
                          permitId={permit.id}
                          readOnly
                        />,
                      )
                    }
                  }}
                  showTooltip
                >
                  {plan?.getSelectedZoneNames(
                    selectedAreas?.map((area) => area.uuid),
                  )}
                </Text>
              </Section>
            )}

          {permit?.getW3wLocation() && (
            <Section label={localizedStrings.pointOfWorks3Words} mt={2}>
              <Text
                color='primary.main'
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  showModal(
                    <SimpleMapModal
                      projectId={permit.project.id}
                      featurePoint={permit?.getW3wLocation()}
                      footerActions={[
                        <Button
                          key={'open-all-permits'}
                          startIcon={<LaunchIcon />}
                          variant={'contained'}
                          onClick={() => {
                            window.open(
                              PageUrls.projectMap({
                                organisationId,
                                projectId: permit.project.id,
                              }),
                              '_blank',
                              'noopener, noreferrer',
                            )
                          }}
                        >
                          {localizedStrings.allPermits}
                        </Button>,
                      ]}
                    />,
                  )
                }}
                showTooltip
              >
                {permit.getW3wLocation().properties.w3wLocation}
              </Text>
            </Section>
          )}

          {permit?.getGeolocationAreas() && (
            <Section label={localizedStrings.permitAreas} mt={2}>
              <Text
                color='primary.main'
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  showModal(
                    <SimpleMapModal
                      projectId={permit.project.id}
                      featurePolygon={permit?.getGeolocationAreas()}
                      footerActions={[
                        <Button
                          key={'open-all-permits'}
                          startIcon={<LaunchIcon />}
                          variant={'contained'}
                          onClick={() => {
                            window.open(
                              PageUrls.projectMap({
                                organisationId,
                                projectId: permit.project.id,
                              }),
                              '_blank',
                              'noopener, noreferrer',
                            )
                          }}
                        >
                          {localizedStrings.allPermits}
                        </Button>,
                      ]}
                    />,
                  )
                }}
              >
                {localizedStrings.viewOnMap}
              </Text>
            </Section>
          )}

          <Section label={localizedStrings.permitStart} mt={2}>
            <Text showTooltip>
              {moment(permit.startsOn).format('DD MMM YYYY HH:mm')}
            </Text>
          </Section>

          <Section label={localizedStrings.permitEnd} mt={2}>
            <Text showTooltip>
              {moment(permit.endsOn).format('DD MMM YYYY HH:mm')}
            </Text>
          </Section>

          <Section label={localizedStrings.permitDuration} mt={2}>
            <Text showTooltip>{permit.getDuration()}</Text>
          </Section>

          {!!permit.getNextNominatedApprovers().length && (
            <Section
              label={localizedStrings.nextNominatedApprover}
              mt={2}
              data-test='nominated-approver-section'
            >
              <Text showTooltip>
                {permit
                  .getNextNominatedApprovers()
                  .map(({ targetApprovers }) =>
                    targetApprovers.map((approver) => approver.name),
                  )
                  .flat()
                  .join(', ')}
              </Text>
            </Section>
          )}
        </CardContent>
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Text
            showTooltip
            sx={{ fontSize: '16px' }}
            variant='h6'
            color='text.primary'
            gutterBottom
          >
            {localizedStrings.people}
          </Text>
          <Divider />

          {permit?.permitAuthor && (
            <Section label={localizedStrings.initiatedBy} mt={2}>
              <Flex
                justifyContent='space-between'
                onClick={() => handlePersonClick(permit.permitAuthor)}
                sx={{ cursor: 'pointer' }}
              >
                <Text showTooltip>{permit.permitAuthor.getFullName()}</Text>
                <ChevronRight />
              </Flex>
            </Section>
          )}

          {permit?.permitHolder && (
            <Section
              label={`${localizedStrings.permitHolder} ${
                permitHolderParticipant?.roles?.length
                  ? `- ${permitHolderParticipant.getRole()}`
                  : ''
              }`}
              mt={2}
            >
              <Flex
                justifyContent='space-between'
                onClick={() =>
                  handlePersonClick(
                    permitHolderParticipant || permit.permitHolder,
                  )
                }
                sx={{ cursor: 'pointer' }}
              >
                <Text showTooltip>{permit.permitHolder.getFullName()}</Text>
                <ChevronRight />
              </Flex>
            </Section>
          )}

          {!!reviews.length &&
            reviews.map((approval) => {
              return (
                <Section
                  key={approval.submittedBy.id}
                  label={approval.getSectionHeading()}
                  mt={2}
                >
                  <Flex
                    justifyContent='space-between'
                    onClick={() => handlePersonClick(approval.submittedBy)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Text showTooltip>
                      {approval.submittedBy.getFullName()}
                    </Text>
                    <ChevronRight />
                  </Flex>
                </Section>
              )
            })}

          <Collapsible
            label={{
              collapsed: localizedStrings.showAll,
              open: localizedStrings.hideAll,
            }}
            pt={4}
          >
            <Text
              sx={{ fontSize: '16px' }}
              variant='h6'
              color='text.primary'
              gutterBottom
              showTooltip
            >
              {localizedStrings.otherParticipants}
            </Text>
            <Divider sx={{ mb: 1 }} />

            {permit?.participants.map((participant) => {
              if (
                participant.userId === permit.permitAuthor?.id ||
                participant.userId === permit.permitHolder?.id
              ) {
                return null
              }
              return (
                <Section
                  key={participant.id}
                  label={participant.getRole()}
                  mt={2}
                >
                  <Flex
                    justifyContent='space-between'
                    onClick={() => handlePersonClick(participant)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Text showTooltip>{participant.getFullName()}</Text>
                    <ChevronRight />
                  </Flex>
                </Section>
              )
            })}
          </Collapsible>
        </CardContent>
      </Card>
    </Box>
  )
}
