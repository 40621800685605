import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { IProjectPlanArea } from './IProjectPlanArea'
import {
  IProjectPlanPSPDFArea,
  PROJECT_PLAN_AREA_COLORS,
} from '../ProjectPlanPSPDFArea'
import { AllShapes, SkiaOval, SkiaPolygon, SkiaRect } from '@core/entities/skia'
import { SiteBoxDocumentAnnotationData } from '@core/entities/SiteBoxDocumentAnnotationData'

export class ProjectPlanArea extends Entity<IProjectPlanArea> {
  uuid: string

  name: string

  color: 'LIGHT_PRIMARY_BLUE'

  annotationData: SiteBoxDocumentAnnotationData

  public static newFromInstantJSON(
    instantJSONAnnotation: IProjectPlanPSPDFArea,
  ): ProjectPlanArea {
    const color = PROJECT_PLAN_AREA_COLORS.find(
      (color) => color.hex === instantJSONAnnotation.fillColor.toUpperCase(),
    )

    const transformToInstanceJSON = {
      uuid: instantJSONAnnotation.id,
      color: color.enum,
      name: instantJSONAnnotation.name,
      annotationData: {
        pageIndex: instantJSONAnnotation.pageIndex,
        type: instantJSONAnnotation.type.split('/').pop().toUpperCase(),
        bbox: instantJSONAnnotation.bbox,
        points: instantJSONAnnotation?.points,
      },
    }

    const entity = plainToInstance(ProjectPlanArea, transformToInstanceJSON)

    return entity
  }

  public static newFromSkiaShape(
    payload: AllShapes,
    color: string,
    name: string,
    { imageWidthInPixels, pageWidthInPoints, pageHeightInPoints },
  ) {
    const enumColor = PROJECT_PLAN_AREA_COLORS.find(
      (areaColor) => areaColor.hex === color,
    )?.enum

    const transformedSkiaShapeToSiteBoxDocumentAnnotation = {
      uuid: payload.id,
      color: enumColor,
      name,
      annotationData: SiteBoxDocumentAnnotationData.newFromSkiaShapeDataImage(
        payload,
        { imageWidthInPixels, pageWidthInPoints, pageHeightInPoints },
      ),
    }

    const entity = plainToInstance(
      ProjectPlanArea,
      transformedSkiaShapeToSiteBoxDocumentAnnotation,
    )

    return entity
  }

  public getPlanAreaShape({
    imageWidthInPixels,
    pageWidthInPoints,
    pageHeightInPoints,
  }: {
    imageWidthInPixels: number
    pageWidthInPoints: number
    pageHeightInPoints: number
  }): AllShapes {
    // Handling shapeGroups

    switch (this.annotationData.type) {
      case 'POLYGON':
        return SkiaPolygon.newFromSiteboxDocumentAnnotationImage(this, {
          imageWidthInPixels,
          pageWidthInPoints,
          pageHeightInPoints,
        })
      case 'RECTANGLE':
        return SkiaRect.newFromSiteboxDocumentAnnotationImage(this, {
          imageWidthInPixels,
          pageWidthInPoints,
          pageHeightInPoints,
        })
      case 'ELLIPSE':
        return SkiaOval.newFromSiteboxDocumentAnnotationImage(this, {
          imageWidthInPixels,
          pageWidthInPoints,
          pageHeightInPoints,
        })
      default:
        return null
    }
  }

  public static new(payload: IProjectPlanArea): ProjectPlanArea {
    const entity = plainToInstance(ProjectPlanArea, payload)

    return entity
  }
}
