import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { useModal } from '@common/GlobalModal'
import { Box, Flex, Text } from '@fundamentals'
import { alpha } from '@mui/material'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import { Permit } from '@core/entities/permit/Permit'
import { PermitCard } from '@modules/permitDetails/PermitCard'

type ProjectPlanPermitsSidebarProps = {
  filteredPermits: Permit[]
  currentPermit: Permit
  permitId: number
  organisationId: number
}

export const ProjectPlanPermitsSidebar: React.FC<
  ProjectPlanPermitsSidebarProps
> = ({ filteredPermits, currentPermit, permitId, organisationId }) => {
  const { showModal } = useModal()
  const [hoveredState, setHoveredState] = useState<{
    permitId: number
    source: 'list' | 'map'
  }>()

  return (
    <Box
      sx={{
        minWidth: '19rem',
        maxWidth: '19rem',
        transition: 'width 0.2s ease-out',
        boxShadow: `0px 3px 5px -1px ${alpha('#000', 0.2)}`,
        overflow: 'scroll',
        background: '#f5f5f5',
        position: 'relative',
      }}
    >
      <Flex
        pt={0.5}
        alignItems='center'
        position='sticky'
        top={0}
        sx={{ background: '#f5f5f5' }}
        zIndex={1}
        flexDirection='column'
      >
        <Text noWrap alignSelf='start' pl={0.7} pb={0.5}>
          {`[${filteredPermits?.length + (!!currentPermit && 1)}] ${
            localizedStrings.permits
          }`}
        </Text>
        {currentPermit && (
          <Box key={currentPermit.id} mr={-1} ml={1} width='100%'>
            <PermitCard
              permit={currentPermit}
              onMouseOver={() => {
                setHoveredState({
                  permitId: currentPermit.id,
                  source: 'list',
                })
              }}
              onMouseLeave={() => setHoveredState(null)}
              hovered={
                hoveredState?.permitId === currentPermit.id ||
                currentPermit.id === permitId
              }
              actions={[
                {
                  label: localizedStrings.details,
                  onClick: () =>
                    showModal(
                      <PermitDetailsModal
                        organisationId={organisationId}
                        permitId={currentPermit.id}
                      />,
                    ),
                },
              ]}
            />
          </Box>
        )}
      </Flex>

      {filteredPermits?.map((permit) => {
        return (
          <Box key={permit?.id} sx={{ mr: -1, ml: 1 }}>
            <PermitCard
              permit={permit}
              onMouseOver={() => {
                setHoveredState({
                  permitId: permit?.id,
                  source: 'list',
                })
              }}
              onMouseLeave={() => setHoveredState(null)}
              hovered={
                hoveredState?.permitId === permit?.id || permit?.id === permitId
              }
              actions={[
                {
                  label: localizedStrings.details,
                  onClick: () =>
                    showModal(
                      <PermitDetailsModal
                        organisationId={organisationId}
                        permitId={permit?.id}
                      />,
                    ),
                },
              ]}
            />
          </Box>
        )
      })}
    </Box>
  )
}
