import { localizedStrings } from '@core/strings'
import React, { useState } from 'react'
import { IconButton, MenuItem, Tooltip } from '@common/material'
import { Box, Flex, Text } from '@fundamentals'
import { CheckBox, MenuOpenOutlined } from '@mui/icons-material'
import { alpha } from '@mui/material'
import { TableSecondaryText } from '@common/Table'
import { ProjectPlanPermits } from '@core/entities/project/ProjectPlanPermits'

import { CanvasState, GroupMetadata, ShapeGroup } from '@core/entities/skia'
import { ProjectPlanArea } from '@core/entities/project/ProjectPlanArea'
import _xor from 'lodash/xor'

type ProjectPlanZonesSidebarProps = {
  planPermits: ProjectPlanPermits
  planAreas: ProjectPlanArea[]
  canvasState: CanvasState
  shapeGroups: ShapeGroup[]
  groupMetadata: GroupMetadata[]
  skiaDiagramRef: React.RefObject<any>
  readOnly?: boolean
  multipleSelection?: boolean
}

export const ProjectPlanZonesSidebar: React.FC<
  ProjectPlanZonesSidebarProps
> = ({
  planPermits,
  planAreas,
  canvasState,
  shapeGroups,
  skiaDiagramRef,
  groupMetadata,
  readOnly = false,
  multipleSelection = false,
}) => {
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  let selectedShapes = []
  canvasState.mode === 'EDIT' &&
    (selectedShapes = canvasState?.selectedShapeIds ?? [])
  canvasState.mode === 'VIEW' &&
    (selectedShapes = canvasState?.selectedShapeIds ?? [])

  const selectedZoneNames = planPermits?.permitStats?.getSelectedAreaNames(
    selectedShapes.length ? selectedShapes : [],
  )

  return (
    <Box
      sx={{
        position: 'relative',
        width: expanded ? '18.5rem' : '4.1rem',
        transition: 'width 0.2s ease-out',
        boxShadow: `0px 6px 10px ${alpha('#000', 0.14)}`,
        overflowY: 'scroll',
        overflowX: 'hidden',
        bgcolor: 'background.paper',
        zIndex: 1,
      }}
    >
      <Flex
        pl={1.4}
        position='sticky'
        top={0}
        zIndex={1}
        flexDirection='column'
      >
        <Flex alignItems='center'>
          <IconButton onClick={handleExpandClick} sx={{ borderRadius: 0 }}>
            <MenuOpenOutlined
              sx={{
                transition: 'transform 0.2s ease-out',
                transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </IconButton>

          <Text noWrap sx={{ opacity: expanded ? 1 : 0 }}>
            {`[${planAreas.length}] ${localizedStrings.zones}`}
          </Text>
        </Flex>

        {expanded && (
          <Tooltip
            title={selectedZoneNames?.length ? selectedZoneNames : ''}
            placement='right'
            arrow
          >
            <Flex
              px={2.6}
              ml={-1.4}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.08)',
                },
              }}
              alignItems={
                selectedZoneNames?.length > 0 ? 'flex-start' : 'center'
              }
            >
              <Text
                opacity={expanded ? 1 : 0}
                fontSize={15}
                color='primary.main'
              >
                {`[${selectedShapes.length}]`}
              </Text>
              <Box pl={1.6} overflow='hidden'>
                <Text
                  noWrap
                  opacity={expanded ? 1 : 0}
                  fontSize={14}
                  color='primary.main'
                >
                  {localizedStrings.selectedZones}
                </Text>

                <Text
                  noWrap
                  opacity={expanded ? 1 : 0}
                  color='text.primary'
                  fontSize={12}
                >
                  {selectedZoneNames}
                </Text>
              </Box>
            </Flex>
          </Tooltip>
        )}
      </Flex>

      {!!shapeGroups.length && (
        <MenuItem
          sx={{
            width: expanded ? '100%' : 'fit-content',
            '&.Mui-disabled': {
              opacity: 0.8,
            },
          }}
          disabled={readOnly}
          onClick={() => {
            if (readOnly) return

            skiaDiagramRef.current.selectGroups(
              null,
              shapeGroups.length === selectedShapes.length
                ? []
                : shapeGroups.map((area) => area.groupId),
              canvasState.mode,
            )
          }}
        >
          <Flex flexDirection='column'>
            <Flex alignItems='center'>
              {shapeGroups.length &&
              shapeGroups.length === selectedShapes.length ? (
                <CheckBox
                  sx={{
                    color: '#000',
                    opacity: 0.6,
                    width: 24,
                    height: 24,
                    ml: '3px',
                    mr: '10px',
                  }}
                />
              ) : (
                <Box
                  mr='13px'
                  ml='6px'
                  height={18}
                  width={18}
                  minWidth={18}
                  borderRadius={0.5}
                  sx={{
                    background: '#000',
                    opacity: 0.6,
                  }}
                />
              )}
              <Text>{localizedStrings.selectAll}</Text>
            </Flex>
          </Flex>
        </MenuItem>
      )}

      {shapeGroups.map((shape) => {
        const isSelected = selectedShapes.includes(shape.groupId)

        const group = groupMetadata.find(
          (group) => group.groupId === shape.groupId,
        )

        return (
          <MenuItem
            key={shape.groupId}
            sx={{
              width: expanded ? '100%' : 'fit-content',
              '&.Mui-disabled': {
                opacity: 0.8,
              },
            }}
            selected={isSelected}
            disabled={readOnly}
            onClick={() => {
              if (readOnly) return

              if (multipleSelection) {
                skiaDiagramRef.current.selectGroups(
                  shape.groupId,
                  _xor(selectedShapes, [shape.groupId]),
                  canvasState.mode,
                )
              } else {
                skiaDiagramRef.current.selectGroups(
                  shape.groupId,
                  [shape.groupId],
                  canvasState.mode,
                )
              }
            }}
          >
            <Flex flexDirection='column'>
              <Flex alignItems='center'>
                {isSelected ? (
                  <CheckBox
                    sx={{
                      color: group?.color,
                      opacity: 1,
                      width: 24,
                      height: 24,
                      ml: '3px',
                      mr: '10px',
                    }}
                  />
                ) : (
                  <Box
                    mr='13px'
                    ml='6px'
                    height={18}
                    width={18}
                    minWidth={18}
                    borderRadius={0.5}
                    sx={{
                      background: group?.color,
                      opacity: 0.6,
                    }}
                  />
                )}

                <Text sx={{ opacity: expanded ? 1 : 0, width: '100px' }}>
                  {group?.groupName}
                </Text>
              </Flex>

              <TableSecondaryText
                sx={{
                  opacity: expanded ? 0.6 : 0,
                  ml: '37px',
                  transition: 'height 0.4s ease-out, opacity 0.1s ease-out',
                  height: expanded ? 'auto' : 0,
                }}
              >
                {`[${
                  planPermits?.permitStats?.getAreaTotalElements(
                    group?.groupId,
                  ) ?? 0
                }] ${localizedStrings.permits}`}
              </TableSecondaryText>
            </Flex>
          </MenuItem>
        )
      })}
    </Box>
  )
}
