import { Entity } from '@core/entities/entity'
import { plainToInstance } from 'class-transformer'
import { IProject } from '@core/entities/project/Project/IProject'
import { ProjectStatus } from '@core/entities/project'
import { ProjectPermission } from '@core/entities/project/Project/types'
import { ProjectRole } from '@core/entities/user'
import { localizedStrings } from '@core/strings'

export class Project extends Entity<IProject> {
  id: number
  organisationId: string | number
  name: string
  code: string
  description: string
  status?: ProjectStatus
  address: {
    line1: string
    postcode: string
    city: string
  }
  startDate: string
  endDate: string
  permittedActions: ProjectPermission[]
  canInviteRoles: ProjectRole[]
  mapBoxConfigResult: {
    mapBoxStyleUrl?: string
  }
  timezone: string
  isTest: boolean
  logoUrl?: string

  public static new(payload: unknown): Project {
    const entity = plainToInstance(Project, payload)

    return entity
  }

  public hasPermission(permission: ProjectPermission): boolean {
    return this.permittedActions?.includes(permission)
  }

  public canSeeAuthorisationGroups(): boolean {
    return this.hasPermission('LIST_GROUPS')
  }

  public canEdit(): boolean {
    return this.hasPermission('EDIT')
  }

  public canEditGroups(): boolean {
    return this.hasPermission('CREATE_GROUPS')
  }

  public canEditSiteLocations(): boolean {
    return this.hasPermission('EDIT_LOCATIONS')
  }

  public canAddPermitTemplateToProjects(): boolean {
    return this.hasPermission('ASSIGN_TEMPLATES')
  }

  public canAddChecklistTemplateToProjects(): boolean {
    return this.hasPermission('ASSIGN_CHECKLIST_TEMPLATES')
  }

  public canAssignPermissionsToTemplate(): boolean {
    return this.hasPermission('ASSIGN_PERMISSIONS')
  }

  public canChangeUserStatus(): boolean {
    return this.hasPermission('CHANGE_MEMBER_STATUS')
  }

  public canViewTimeline(): boolean {
    return this.hasPermission('LIST_AUDITS')
  }

  public canUpdateUserAuthGroups(): boolean {
    return this.hasPermission('UPDATE_USER_AUTH_GROUPS')
  }

  public canChangeUserRole(): boolean {
    return this.hasPermission('CHANGE_MEMBER_ROLE')
  }

  public canSeeAllPermits(): boolean {
    return this.hasPermission('SEE_ALL_PERMITS')
  }

  public canDeleteProjectUser(): boolean {
    return this.hasPermission('CHANGE_MEMBER_STATUS')
  }

  public static statusText(status: ProjectStatus) {
    switch (status) {
      case 'LIVE':
        return localizedStrings.live
      case 'ARCHIVED':
        return localizedStrings.archived
      case 'TEST':
        return localizedStrings.test
      default:
        return status
    }
  }

  public static statusColour(status: ProjectStatus) {
    switch (status) {
      case 'LIVE':
        return 'success'
      default:
        return 'default'
    }
  }
}
