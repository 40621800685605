import { localizedStrings } from '@core/strings'
import React from 'react'
import {
  PermitListBusinessUnitScope,
  PermitListProjectScope,
  PermitListScopes,
} from '@modules/permits/PermitListLegacy'
import { useRouter } from 'next/router'
import { useQueryParamFilterStore } from '@elements/Filters'
import { useProcessTagsFilter, useSelectFilter } from '@core/providers/filters'
import { useProjectsFilter } from '@core/providers/filters/useProjectsFilter'
import { usePermitTemplatesFilter } from '@core/providers/filters/usePermitTemplatesFilter'
import { useTextInputFilter, useDateRangeFilter } from '@core/providers/filters'
import { useUserSearchFilter } from '@core/providers/filters/useUserSearchFilter'
import { useDeepCompareMemo } from 'use-deep-compare'
import { useProjectLocationParentOnlyFilter } from '@core/providers/filters/useProjectLocationParentOnlyFilter'
import { TableUserSummaryCard } from '@common/Table'
import { useDebouncedValue } from '@core/utils/useDebouncedValue'
import { usePermitStatusFilter } from '@core/providers/filters/usePermitStatusFilter'
import {
  PermitSort,
  PermitSortOptions,
  PermitStatus,
} from '@core/entities/permit/PermitBase'
import { ProcessTagColorBadge } from '@common/ProcessTagColorBadge'
import { ProcessTagChip } from '@common/ProcessTagChip'

export const usePermitFilters = (
  scope: PermitListScopes,
  options?: {
    templateFilterProps?: { whitelist?: number[]; readOnly?: boolean }
    projectFilterProps?: { whitelist?: number[]; readOnly?: boolean }
    statusFilterProps?: { whitelist?: PermitStatus[]; readOnly?: boolean }
  },
) => {
  const router = useRouter()
  const { templateFilterProps, projectFilterProps, statusFilterProps } =
    options ?? {}

  const filters = {
    processTagIds: useProcessTagsFilter({
      label: localizedStrings.tags,
      scope,
      multiple: true,
      isPinned: true,
      renderItem: (tag) => (
        <>
          <ProcessTagColorBadge color={tag?.color} sx={{ mr: 1 }} />
          {tag?.name}
        </>
      ),
      renderTags: (tags) => {
        return tags.map((tag, index) => {
          return (
            <ProcessTagChip key={index} tag={tag} sx={{ mr: 0.5, my: 0.5 }} />
          )
        })
      },
    }),
    templateIds: usePermitTemplatesFilter({
      label: localizedStrings.permitType,
      scope: scope,
      multiple: true,
      whitelist: templateFilterProps?.whitelist,
      readOnly: templateFilterProps?.readOnly,
    }),
    statuses: usePermitStatusFilter({
      multiple: true,
      whitelist: statusFilterProps?.whitelist,
      readOnly: statusFilterProps?.readOnly,
    }),
    summary: useTextInputFilter({
      label: localizedStrings.summary,
    }),
    projectIds: useProjectsFilter({
      label: localizedStrings.project,
      organisationId: scope.organisationId,
      businessUnitId: (scope as PermitListBusinessUnitScope)?.businessUnitId,
      multiple: true,
      isHidden: scope.type === 'PROJECT',
      whitelist: projectFilterProps?.whitelist,
      readOnly: projectFilterProps?.readOnly,
    }),
    dateRange: useDateRangeFilter({
      label: localizedStrings.activeDate,
      dataTest: 'date-range-filter',
    }),
    startDate: useDateRangeFilter({
      label: localizedStrings.startDate,
      dataTest: 'start-date-filter',
    }),
    createdDate: useDateRangeFilter({
      label: localizedStrings.createdDate,
      dataTest: 'created-date-filter',
    }),
    uuid: useTextInputFilter({
      label: localizedStrings.permitUniqueCode,
    }),
    userId: useUserSearchFilter({
      initialValue: parseInt(router.query.userId as string),
      label: localizedStrings.userInvolved,
      scope,
      renderItem: (user) => <TableUserSummaryCard user={user} />,
    }),
    permitAuthorId: useUserSearchFilter({
      initialValue: parseInt(router.query.permitAuthorId as string),
      label: localizedStrings.initiatedBy,
      scope,
      renderItem: (user) => <TableUserSummaryCard user={user} />,
    }),
    permitHolderId: useUserSearchFilter({
      initialValue: parseInt(router.query.permitHolderId as string),
      label: localizedStrings.permitHolder,
      scope,
      renderItem: (user) => <TableUserSummaryCard user={user} />,
    }),
    approvedBy: useUserSearchFilter({
      initialValue: parseInt(router.query.approvedBy as string),
      label: localizedStrings.approvedBy,
      scope,
      renderItem: (user) => <TableUserSummaryCard user={user} />,
    }),
    locationIds: useProjectLocationParentOnlyFilter({
      label: localizedStrings.location,
      projectId: (scope as PermitListProjectScope)?.projectId,
      multiple: true,
    }),
    sort: useSelectFilter({
      label: localizedStrings.sortBy,
      options: PermitSortOptions,
      valueToLabel: (v) => Utils.getTextForPermitSort(v),
      dataTest: 'sort-filter',
    }),
  }

  const queryParamsStore = useQueryParamFilterStore({
    filters,
    queryParams: {
      statuses: 'statuses',
      summary: 'summary',
      projectIds: 'projectIds',
      templateIds: 'templateIds',
      sort: 'sort',
      dateRange: 'dateRange',
      startDate: 'startDate',
      createdDate: 'createdDate',
      uuid: 'uuid',
      permitHolderId: 'permitHolderId',
      permitAuthorId: 'permitAuthorId',
      approvedBy: 'approvedBy',
      locationIds: 'locationIds',
      userId: 'userId',
      processTagIds: 'processTagIds',
    },
    initialValues: {
      templateIds: templateFilterProps?.whitelist ?? [],
      projectIds: projectFilterProps?.whitelist ?? [],
      statuses: statusFilterProps?.whitelist ?? [],
    },
    whitelistValues: { sort: Object.values(PermitSort) },
    onChangeOverride: {
      page: 1,
    },
  })

  const filterValues = useDebouncedValue(queryParamsStore.values, 200)
  const payload = useDeepCompareMemo(() => {
    let projectIds = null
    let businessUnitId = null
    let locationIds = null
    let hideTestProjects = false

    if (scope.type === 'PROJECT') {
      projectIds = scope.projectId
      locationIds = filterValues.locationIds
    }

    if (scope.type === 'ORGANISATION') {
      projectIds = filterValues.projectIds
      hideTestProjects = true
    }

    if (scope.type === 'BUSINESS_UNIT') {
      businessUnitId = scope.businessUnitId
      projectIds = filterValues?.projectIds
    }

    return {
      organisationId: scope.organisationId,
      projectIds,
      businessUnitId,
      dateRange:
        filterValues?.dateRange?.startTime && filterValues?.dateRange?.endTime
          ? {
              startTime: filterValues?.dateRange?.startTime,
              endTime: filterValues?.dateRange?.endTime,
            }
          : null,
      startDate:
        filterValues?.startDate?.startTime && filterValues?.startDate?.endTime
          ? {
              startsOnRangeStartTime: filterValues?.startDate?.startTime,
              startsOnRangeEndTime: filterValues?.startDate?.endTime,
            }
          : null,
      createdDate:
        filterValues?.createdDate?.startTime &&
        filterValues?.createdDate?.endTime
          ? {
              createdOnRangeStartTime: filterValues?.createdDate?.startTime,
              createdOnRangeEndTime: filterValues?.createdDate?.endTime,
            }
          : null,
      statuses: filterValues?.statuses,
      summary: filterValues?.summary,
      templateIds: filterValues?.templateIds,
      locationIds,
      approvedBy: filterValues?.approvedBy as number,
      permitHolderId: filterValues?.permitHolderId as number,
      userId: filterValues?.userId as number,
      permitAuthorId: filterValues?.permitAuthorId as number,
      uuid: filterValues?.uuid,
      sort: filterValues?.sort as string,
      hideTestProjects,
      processTagIds: filterValues?.processTagIds,
    }
  }, [scope, filterValues, router.query?.page, router.query?.size])

  return {
    payload,
    filterValues,
    filters,
    queryParamsStore,
  }
}
