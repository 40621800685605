import { localizedStrings } from '@core/strings'
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@common/material'
import { OrganisationRole, OrganisationUser } from '@core/entities/user'
import React from 'react'

type OrganisationUserPermissionsSelectProps = {
  organisationId: number
  roles: OrganisationRole[]
  onRolesChange: (roles: OrganisationRole[]) => void
}

export const OrganisationUserPermissionsSelect: React.FC<
  OrganisationUserPermissionsSelectProps
> = ({ organisationId, roles, onRolesChange }) => {
  const roleOptions: OrganisationRole[] = [
    'ACCOUNT_OWNER',
    'ACCOUNT_VIEWER',
    'DEVELOPER',
  ]

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id='role-select-label'>
          {localizedStrings.permissions}
        </InputLabel>
        <Select
          labelId='role-select-label'
          id='role-select'
          value={roles}
          sx={{ mb: 2 }}
          multiple
          label={localizedStrings.permissions}
          onChange={(values) => {
            let rolesToSave = values.target.value as OrganisationRole[]
            if (!rolesToSave?.length) {
              // We always need to send at least one role to the back-end, if no roles selected, then we send SUPERVISOR
              rolesToSave = ['USER']
            } else if (
              // If any role except supervisor is selected, then we remove supervisor from the list
              rolesToSave?.length > 1 &&
              rolesToSave.includes('USER')
            ) {
              rolesToSave = rolesToSave.filter((r) => r !== 'USER')
            }
            onRolesChange(rolesToSave)
          }}
          renderValue={(selected) => {
            return selected?.map((value) => (
              <Chip
                sx={{ mr: 1 }}
                key={value}
                label={OrganisationUser.roleString(value as OrganisationRole)}
              />
            ))
          }}
        >
          {roleOptions?.map((role) => (
            <MenuItem key={role} value={role}>
              {OrganisationUser.roleString(role)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
