import { localizedStrings } from '@core/strings'
import React from 'react'
import { IconButton, Tooltip } from '@common/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { LinkedProcessItemResult } from '@core/entities/linkedProcesses'
import { LinkedPeriodicCheckCard } from './LinkedPeriodicCheckCard'
import PeriodicCheckEntryModal from '@modules/permitDetails/modals/PeriodicCheckEntryModal'
import { useModal } from '@common/GlobalModal'
import { ChecklistCard, PermitCard } from '../Cards'
import { PermitDetailsModal } from '@modules/permitDetails/PermitDetailsModal'
import ChecklistDetailsModal from '@modules/permitDetails/modals/ChecklistDetailsModal'

type LinkedDocumentsListProps = {
  organisationId: number
  items: LinkedProcessItemResult[]
  permitId?: number
}

export const LinkedDocumentsList: React.FC<LinkedDocumentsListProps> = ({
  items,
  organisationId,
  permitId,
}) => {
  const { showModal } = useModal()

  return (
    <>
      {items?.map((item) => {
        if (['PERMIT', 'REFERENCED_BY_PERMIT'].includes(item.type)) {
          return (
            <PermitCard
              key={item.linkedPermitResult.id}
              templateName={item.linkedPermitResult.name}
              icon={
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => {
                    showModal(
                      <PermitDetailsModal
                        organisationId={organisationId}
                        permitId={item.linkedPermitResult.id}
                      />,
                    )
                  }}
                >
                  <Tooltip title={localizedStrings.viewPermit}>
                    <LaunchIcon fontSize='small' />
                  </Tooltip>
                </IconButton>
              }
              sx={{ mb: 2 }}
              {...item.linkedPermitResult}
            />
          )
        }

        if (['CHECKLIST', 'REFERENCED_BY_CHECKLIST'].includes(item.type)) {
          return (
            <ChecklistCard
              key={item.linkedChecklistResult.id}
              icon={
                <IconButton
                  color='primary'
                  size='small'
                  onClick={() => {
                    showModal(
                      <ChecklistDetailsModal
                        checklistId={item.linkedChecklistResult.id}
                      />,
                    )
                  }}
                >
                  <Tooltip title={localizedStrings.viewChecklist}>
                    <LaunchIcon fontSize='small' />
                  </Tooltip>
                </IconButton>
              }
              sx={{ mb: 2 }}
              {...item.linkedChecklistResult}
            />
          )
        }

        if (item.type === 'PERIODIC_CHECK') {
          return (
            <LinkedPeriodicCheckCard
              key={item.linkedPeriodicChecksResult.id}
              linkedPeriodicChecksResult={item.linkedPeriodicChecksResult}
            >
              <IconButton
                color='primary'
                size='small'
                onClick={() => {
                  showModal(
                    <PeriodicCheckEntryModal
                      periodicCheckId={item.linkedPeriodicChecksResult.id}
                      periodicCheckTemplateId={
                        item.linkedPeriodicChecksResult.templatePeriodicCheckId
                      }
                      permitId={permitId}
                      organisationId={organisationId}
                    />,
                  )
                }}
              >
                <Tooltip title={localizedStrings.viewChecklist}>
                  <LaunchIcon fontSize='small' />
                </Tooltip>
              </IconButton>
            </LinkedPeriodicCheckCard>
          )
        }
      })}
    </>
  )
}
