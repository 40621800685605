import LocalizedStrings, {
  LocalizedStrings as LocalizedStringsGenericType,
} from 'react-localization'

/* ------------- Types ------------- */
export type StringsType = typeof enBaseStrings

export type LocalizedStringsType = LocalizedStringsGenericType<StringsType>

type SupportedLanguagesType = 'en'

/* ------------- Strings ------------- */
/* eslint sort-keys-fix/sort-keys-fix: ["error", "asc", { caseSensitive: false, natural: false }] */
const enBaseStrings = {
  aboutToExpire: (n: number) => `Permit will expire in ${n} minutes`,
  accept: 'Accept',
  acceptedValues: 'Accepted Values',
  acceptPermitTransferAcknowledge:
    'By signing this transfer you acknowledge your responsibility as the new Permit Holder.',
  acceptTransfer: 'Accept Transfer',
  accountOwner: 'Account Owner',
  accountViewer: 'Account Viewer',
  acknowledge: 'Acknowledge',
  acknowledged: 'Acknowledged',
  acknowledgeDisclaimer: 'Please check this box to acknowledge the disclaimer',
  acknowledgeDocument: 'Acknowledge Document',
  acknowledgeQuestionPrompt: 'Please review and acknowledge this document',
  action: 'Action',
  actionBy: (action: string, user: boolean) => `${action}${user ? ' by' : ''}`,
  actions: 'Actions',
  actionType: 'Action Type',
  activate: 'Activate',
  activateActionMessage: 'You must finish editing to activate this action',
  activatedBy: 'Activated by',
  activatedChecklistVersion: 'Activated Checklist Version',
  activatedOrganisationMember: 'Activated Organisation Member',
  activatedPermitTemplateVersion: 'Permit Version Activated',
  activatedProjectMember: 'Activated Project Member',
  activateVersion: 'Activate Version',
  activateVersionText:
    'Making this active will make it the current permit template version.',
  active: 'Active',
  activeDate: 'Active Date',
  add: 'Add',
  addALevel: 'Add a level',
  addALocation: 'Add a location',
  addAnotherUser: 'Add Another User',
  addAttachment: 'add an attachment',
  addChecklist: 'Add Checklist',
  addChecklistsToProject: 'Add Checklists To Project',
  addComment: 'Add Comment',
  addCondition: 'Add Condition',
  addDiagram: 'Add Diagram',
  addDocument: 'Add Document',
  addDocuments: 'Add Documents',
  addedChecklistTemplateToProject: 'Checklist Template Added to Project',
  addedPermitTemplate: 'Added Permit Template to Project',
  addedProperties: 'Added Properties',
  addedUserToAuthGroup: 'Added User to Auth Group',
  addedUserToOrganisation: 'Added User to Organisation',
  addedUserToProject: 'Added User to Project',
  addExistingSiteAssistUser: 'Existing SiteAssist User',
  addExternalUser: 'Add External User',
  addFileOrFolder: 'Add File or Folder',
  addFileOrFolderDescription: 'Create a new folder or upload a file',
  addFilter: 'Add Filter',
  addGroupMembers: 'Add a user to the Group',
  addGroupMembersFromProject:
    'Add the members of this group from your project team',
  addingParticipants: 'Adding Participants',
  addInspection: 'Add Inspection',
  addItem: 'Add Item',
  addItems: 'Add Items',
  AdditionalInformation: 'Additional Information',
  addLink: 'Add Link',
  addLocation: 'Add Location',
  addMember: 'Add Member',
  addOrganisationLink: 'Add Link to Organisation',
  addOrRemoveTemplates: 'Add or Remove Permit Templates',
  addParticipant: 'Add Participant',
  addParticipants: 'Add Participants',
  addParticipantsAction: 'add participants',
  addParticipantsAndInformation: 'Add Participants & Information',
  addParticipantsAndInformationDescription:
    'Please add the participants below that you would like to brief. You may be required to capture your current location, and/or add a photo to continue.',
  addParticipantsDescription: 'Add additional participants to the permit',
  addParticipantsManuallyDescription: `You will collect the Participant signatures by tapping Done`,
  addParticipantsToRoleDescription:
    'If the user is already on SiteAssist you can search for them here. If not they can be added separately via their name and Company',
  addPermission: 'Add Permission',
  addProject: 'Add Project',
  addRegisterItem: 'Add Register Item',
  address: 'Address',
  addressChanges: 'Address Changes',
  addressedChange: 'addressed a change to this permit',
  addRule: 'Add Rule',
  addSignature: 'Add Signature',
  addTemplatesToProject: 'Add Templates To Project',
  addToProject: 'Add to Project',
  addToTemplate: 'Add to Template',
  addTrigger: 'Add Trigger',
  addUsers: 'Add Users',
  addUserWithoutSaAccount: 'Person Without SiteAssist Account',
  addWarning: 'Add Warning',
  afterPermitHolder: 'After Permit Holder',
  alert: 'Alert',
  all: 'All',
  allChecklists: 'All Checklists',
  AllChecklists: 'All Checklists',
  allowApprovalDrafts: 'Allow Approval Drafts',
  allowDefaultsToBeOverriden: 'Allow defaults to be overridden',
  allowExtensionBeyondMaxDuration: 'Allow extension beyond max duration',
  allowOverride: 'Allow Override',
  allPermits: 'All Permits',
  allProjects: 'All Projects',
  allTags: 'All Tags',
  alreadyUpToDate: 'You are already up to date',
  analytics: 'Analytics',
  analyticsChatbot: 'Analytics Chatbot',
  android: 'Android',
  aNewVersion: 'A new version of SiteAssist is available',
  annotation: 'Annotation',
  annotations: 'Annotations',
  annotationsUpdated: 'Annotations updated',
  answer: 'Answer',
  answerHere: 'Answer here',
  answerRequiredQuestions: 'Answer the required questions for the Permit',
  answers: 'Answers',
  answersNotSubmittedYet: 'Answers not submitted yet',
  answerUsedByRule: 'Used by Conditional Logic',
  any: 'Any',
  apiKeyCreated: 'API Key Created',
  apiKeyDeleted: 'API Key Deleted',
  apiKeyDeletionConfirmation: 'API Key Deletion Confirmation',
  apiKeys: 'API Keys',
  apiKeySecretWarning:
    ' Please make sure to copy the client secret. As it will not ever be displayed afterwards.',
  apply: 'Apply',
  applyLocationSettingsForThisChecklistTemplate:
    'Apply location settings for this Checklist Template',
  approval: 'Approval',
  approvalConditionalLogicDescription:
    'Set the conditions that will trigger this approval.',
  approvalNoRuleText: 'There is no rules associated with this approval.',
  approvalOrder: 'Approval Order',
  approvalOrderText:
    'Is there any order that you would like the approval to take place?',
  approvalRequest: 'Approval Request',
  approvalRequestInfo: 'Issue the permit to begin work on site',
  approvalRequestInfoDraft: `You've already begun the approval process for this permit. To continue, click 'Approve'.`,
  approvals: 'Approvals',
  approve: 'Approve',
  approved: 'Approved',
  approvedBy: 'Approved By',
  approvedExtension: 'Extension Approved',
  approveExtension: 'Approve Extension',
  approvePermit: 'Approve Permit',
  approvePermitTransfersTitle: 'Who can approve permit transfers?',
  approver: 'Approver',
  approverQuestions: 'Approver Questions',
  approvers: 'Approvers',
  approveTransfer: 'Approve Transfer',
  appUpdatedSuccessfully: 'App Updated Successfully',
  archive: 'Archive',
  archived: 'Archived',
  archivedChecklistTemplate: 'Checklist Template Archived',
  archivedLocation: 'Archived Location',
  archivedPermitTemplate: 'Archived Permit Template',
  archivedPlan: 'Archived Plan',
  areYouSure: 'Are you sure?',
  areYouSureArchiveLocation: 'Are you sure you want to archive?',
  assignment: 'Assignment',
  assignTo: 'Assigned To',
  assignToUserProperty: 'Assign to User Property',
  attachmentInputDragPrompt: 'Or drag & drop file in this area',
  attachPDF: 'Attach PDF',
  authGroupChecklistPermissionsUpdated:
    'Auth Group Checklist Permissions Updated',
  authGroupCreated: 'Auth Group Created',
  authGroupDeleted: 'Auth Group Deleted',
  authGroupNameUpdated: 'Auth Group Name Updated',
  authGroupPermitPermissionsUpdated: 'Auth Group Permit Permissions Updated',
  authGroupRegisterPermissionsUpdated:
    'Auth Group Register Permissions Updated',
  authGroupTemplateRolesText:
    'By selecting one of the below roles, this means that only the users added to this Authorisation Group will be able to be briefed onto this permit type under the specified role',
  authorisation: 'Authorisation',
  authorisationGroup: 'Authorisation Group',
  authorisationGroups: 'Authorisation Groups',
  authorisedPermits: 'Authorised Permits',
  authorisedPermitsToolTip:
    'People in this group will be able to Issue these Permits',
  authorisedPerson: 'Authorised Person',
  authorisedPersonQuestions: 'Authorised Person Questions',
  authorisedPersonTooltip:
    'The Authorised Person or Project Admin will start the process and issue the permit to a Permit Holder.',
  authPersonQuestions: 'Authorised Person Questions',
  automatedEvent: 'Automated Event',
  automatedInformation: 'Automated Information',
  autoRefreshEveryMinute: 'Auto Refresh (every minute)',
  awaitingApproval: 'Awaiting Approval',
  awaitingApprovalNotification: 'Awaiting Approval Notification',
  awaitingFinalSignOff: 'Final Sign Off',
  awaitingParticipantSignatures: 'Awaiting Participant Signatures',
  awaitingPostPermitChecks: 'Post Permit Checks',
  back: 'Back',
  backToOrganisation: 'To Organisation',
  backToProject: 'Back to Project',
  baseTemplate: 'Base Template',
  basicInformation: 'Basic Information',
  basicInformationDescription: 'Enter some basic information about the Permit',
  beBriefedAs: 'Be briefed as',
  beforePermitHolder: 'Before Permit Holder',
  bestUserExperienceProjectPlan:
    'For the best experience view this plan in horizontal view',
  beta: 'Beta',
  betaAccess: 'Beta access',
  briefAndCollectSignatures: 'Brief & Collect Signatures',
  briefAndSign: 'Brief & Sign',
  briefedParticipant: 'Briefed Participant',
  briefExistingParticipants: 'Brief Existing Participants',
  briefExistingParticipantsDescription:
    'Brief participants already added to the permit',
  briefingAttendanceRecord: 'Briefing Attendance Record',
  briefingAttendanceRecordDescription:
    'Please select your role and submit your signature to capture your acknowledgement and understanding of the briefing disclaimer and contents of this checklist.',
  briefingAttendanceRecordExternalDescription:
    'Please enter your details in order to capture the briefing attendance record.',
  briefingAttendanceRecordExternalSigning:
    'Please submit your signature to capture your acknowledgement and understanding of the briefing disclaimer and contents of this checklist.',
  briefingDescription:
    'This briefing material must be shown to any new participants joining',
  briefingDisclaimer: 'Briefing Disclaimer',
  briefingLog: 'Briefing Log',
  briefingParticipant: 'Briefing Participant',
  briefingParticipants: 'Briefing Participants',
  briefingText: 'Briefing Text',
  briefParticipant: 'Brief Participant',
  briefParticipants: 'Brief Participants',
  briefParticipantsChecklistDescription:
    'Brief participants onto this checklist',
  briefParticipantsPermitDescription: 'Brief participants onto the permit',
  briefTask: 'Brief Task',
  browseFile: 'Browse File',
  businessUnit: 'Business Unit',
  businessUnitCreated: 'Business Unit Created',
  businessUnitDeleted: 'Business Unit Deleted',
  businessUnitOverview: 'Overview',
  businessUnits: 'Business Units',
  businessUnitUpdated: 'Business Unit Updated',
  by: 'By',
  bySigningText:
    'By signing this you are requesting a permit.\n' +
    'We will send it to your project admin so that they can review it.',
  calculated: 'Calculated',
  calculatedByFormula: 'Calculated by formula',
  calculateDurationBasedOnDays: 'Calculate duration based on days',
  calibrate: 'Calibrate',
  calibrateMapInstructions:
    "Move the map and press calibrate when you're happy with the position",
  camera: 'Camera',
  cameraAccess: 'Camera access',
  cameraAccessMessage: 'SiteAssist needs camera access to capture proof',
  cameraAndfileReadAccess: 'Camera and File Read access',
  cameraAndFileReadAccessMessage:
    'SiteAssist needs camera and file read access to upload proofs',
  cancel: 'Cancel',
  cancellationReason: 'Cancellation Reason',
  cancelled: 'Cancelled',
  cancelPermit: 'Cancel Permit',
  cancelPermitDescription: 'Mark this permit as no longer valid',
  cancelPermitReason: 'Please provide the reason for cancelling the permit.',
  cancelReason: 'Cancel Reason',
  cancelTransfer: 'Cancel Transfer',
  cannotAddSubLocation: 'Cannot Add Sub Location',
  cannotAddSubLocationMessage:
    'You cannot add a sub-location to a location with a plan',
  cannotEditLocationsMessage:
    'You cannot edit locations, only Project Admins are allowed to edit Site Locations',
  cannotEditTemplateMessage:
    "To make changes to these settings, please contact your organisation's Account Owner, or help@siteassist.co.uk",
  cannotUploadPlan: 'Cannot Upload Plan',
  cannotUploadPlanMessage:
    'You cannot upload a plan to a location with sub-locations',
  captureLocationAction: 'capture a location',
  changeAuthGroups: 'Change Authorisation Groups',
  changedProfileInformation: 'Changed Profile Information',
  changedProjectExternalCode: 'Project External Code Changed',
  changedProjectStatus: 'Project Status Changed',
  changePassword: 'Change Password',
  changePermissions: 'Change Permissions',
  changePermitHolderInformation: 'Change Permit Holder Information',
  changeRole: 'Change Role',
  changesAddressed: 'Changes Addressed',
  changesAddressedNotifications: 'Changes addressed',
  changesDone: 'Changes Done',
  changesRequested: 'Changes Requested',
  changesRequestedNotifications: 'Changes requested',
  changeTemplates: 'Change Templates',
  characterCount: 'Character count',
  checkedBy: 'Checked By',
  checkForUpdates: 'Check for Updates',
  checkin: 'Check In',
  checkinLocation: 'Check In Location',
  checkinPrompt:
    'Please use the button below to indicate your location on the map',
  checklist: 'Checklist',
  checklistActions: 'Checklist Actions',
  checklistAnswerRequiredQuestions:
    'Answer the required questions for the Checklist',
  checklistAuthor: 'Checklist Author',
  checklistBasicInformationDescription:
    'Enter some basic information about the Checklist',
  checklistClonable: 'Checklist Clonable',
  checklistCloned: 'Checklist Cloned',
  checklistCompleted: 'Checklist Completed',
  checklistCreator: 'Checklist Creator',
  checklistDetails: 'Checklist Details',
  checklistDownloadComplete: 'Checklist Download Complete',
  checklistDownloadFailed: 'Checklist Download Failed',
  checklistDraftSaved: 'Checklist Draft Saved',
  checklistDraftUpdated: 'Checklist Draft Updated',
  checklistFilter: 'Checklists Filter',
  checklistId: 'Checklist ID',
  checklistInformation: 'Checklist Information',
  checklistInitiationPermissionMessage:
    'When this permission is applied, a user must be in an Authorisation Group that has this permission enabled to raise the checklist. Any Users not in one of these Auth Groups will not be able to raise this checklist template.',
  checklistList: 'Checklist List',
  checklistPermissions: 'Checklist Permissions',
  checklistQRCodeDescription:
    'Scan this QR code with your phone camera to open the checklist.',
  checklistQuestions: 'Checklist Questions',
  checklistRole: 'Checklist Role',
  checklists: 'Checklists',
  checklistSubmitted: 'Checklist Submitted',
  checklistSummary: 'Checklist Summary',
  checklistTemplate: 'Checklist Template',
  checklistTemplates: 'Checklist Templates',
  checklistTimeline: 'Checklist Timeline',
  checklistType: 'Checklist Type',
  checklistUniqueCode: 'Checklist Unique Code',
  checksComplete: 'Checks Complete',
  chooseFolder: 'Choose Folder',
  chooseFromSitebox: 'Choose from SiteBox',
  choosePermitHolder: 'Choose the Permit Holder',
  chooseUpTo3PropertiesToShowAsLabels:
    'Choose up to 3 properties to show as labels',
  city: 'City',
  clear: 'Clear',
  clearAll: 'Clear all',
  clearSignature: 'Clear Signature',
  clickThePlusButtonTo: (action: string) =>
    `Click the plus button to ${action}`,
  clientId: 'Client ID',
  clientSecret: 'Client secret',
  clone: 'Clone',
  cloneChecklist: 'Clone Checklist',
  cloneChecklistDescription: 'Clone this checklist to a new draft',
  clonedPermitDetected: 'Cloned Permit Detected',
  clonePermit: 'Clone Permit',
  clonePermitDescription: 'Clone this permit to a new draft',
  close: 'Close',
  closed: 'Closed',
  closePermit: 'Close Permit',
  closePermitDescription: 'Declare this permit complete',
  closePermitParagraph:
    'Are you sure? This Permit will no longer be open and work has to stop immediately.',
  closeShape: 'Close Shape',
  code: 'Code',
  colorProperty: 'Color Property',
  colorPropertyDescription:
    'Choose a property to color-code items on the plan.',
  colour: 'Colour',
  comment: 'Comment',
  commentedOnAQuestion: 'commented on a question',
  commentLowerCase: 'comment',
  comments: 'comments',
  commentsCount: (count: number) => `${count} Comment${count === 1 ? '' : 's'}`,
  commentsTitle: 'Comments',
  companies: 'Companies',
  company: 'Company',
  complete: 'Complete',
  completeAtPointOfWorks:
    'You must complete this document at the point of works using a mobile device',
  completed: 'Completed',
  completedBy: 'Completed By',
  completedChecks: 'Completed Checks',
  completePostPermitChecks: 'Complete Post Permit Checks',
  completeProfile:
    'Hi <strong>{0}</strong>, you’ve been added as an <span class="text-bold">{1}</span> in the <span class="text-bold">{2}</span> Organisation. Please confirm a few details to get started.',
  completeProfileMobile:
    'Hi {0}, you’ve been added as an {1} in the {2} Organisation. Please confirm a few details to get started.',
  completeTask: 'Complete Task',
  completeYourProfile: 'Complete Your Profile',
  completion: 'Completion',
  conditionalLogic: 'Conditional Logic',
  conditionalLogicDescription:
    'Create rules to define the visibility of the questions in this template.',
  conditionalQuestion: 'Conditional Question',
  conditionalQuestionGroup: 'Conditional Question Group',
  conditionalQuestionGroupExplanation:
    'If answering "{0}", you will proceed to Question {1}',
  conditionalQuestionGroupTip:
    'The permit requester will skip this question group, depending on their answer',
  conditionsChipText: (length: number) =>
    `Conditions: ${length} rule${length !== 1 ? 's' : ''}`,
  configuration: 'Configuration',
  configureTemplateSettings: 'Configure the settings for your template.',
  configureTemplateSettingsText: 'Configure the settings for your template.',
  confirm: 'Confirm',
  confirmAcknowledgeText: 'You must acknowledge this document to proceed.',
  confirmActivateChecklistTemplateVersion:
    'Are you sure you want to activate this version? This means all future checklists will use this version.',
  confirmActivateTemplateVersion:
    'Are you sure you want to activate this version? This means all future permits will use this version.',
  confirmAreas: (featureCount: number) => `Confirm ${featureCount} Area(s)`,
  confirmDeleteBusinessUnit:
    '<p>Are you sure you want to <span class="text-red text-bold">delete</span> business unit <span class="text-red text-bold">{0}</span>?</p>',
  confirmDeleteGroup:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0}</span>? This action can not be undone.',
  confirmDeleteLocation:
    '<p>Are you sure you want to <span class="text-red text-bold">delete</span> this location? Your changes will not be saved until you save locations.</p>',
  confirmDeleteMember:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0} {1}</span> from group <span class="text-bold">{2}</span>? This action can not be undone.',
  confirmDeleteOrganisationLink: 'Are you sure you want to delete this link?',
  confirmDeletePermitRole:
    'Are you sure you want to delete this role? This action can’t be undone.',
  confirmDeleteProjectFromBusUnit:
    'Are you sure you want to delete this project from the business unit?',
  confirmDeleteQuestion: 'Are you sure you want to delete this question?',
  confirmDeleteQuestionGroup:
    '<p>Are you sure you want to <span class="text-red text-bold">delete</span> this question group? Your changes will not be saved until you save the template.</p>',
  confirmDeleteTaskStep: 'Are you sure you want to delete this step?',
  confirmDeleteTemplate:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0}</span> from the project?',
  confirmDeleteTemplateVersion:
    'Are you sure you want to delete this version? This action can’t be undone.',
  confirmDeleteText:
    "Are you sure you want to <span class='text-red text-bold'>delete</span> this question?",
  confirmDeleteUser:
    'Are you sure you want to <span class="text-red text-bold">delete</span> <span class="text-bold">{0} {1}</span>? This action can not be undone.',
  confirmInitiatorRoleChange:
    'Are you sure you want to change the initiator role? This might cause some data loss.',
  confirmLeavePage:
    'You have unsaved changes. Are you sure you want to leave this page?',
  confirmLeavePageSaveDraft:
    'You have unsaved changes. Are you sure you want to leave this page and discard this draft?',
  confirmLocation: 'Confirm Location',
  confirmNewPassword: 'Confirm new password',
  confirmPassword: 'Confirm Password',
  confirmResendInviteUser:
    'Are you sure you want to <span class="text-bold">resend</span> an invite to <span class="text-bold">{0} {1}</span>?',
  confirmSaveTemplate: 'Are you sure you want to save this template?',
  confirmSignature: 'Confirm Signature',
  confirmToContinueRaisingAPermit: 'Confirm to continue raising permit',
  confirmUnsavedChanges:
    'You have made some changes to the version. You must save the version before you can Activate it',
  contactNumber: 'Contact Number',
  contactParticipant: 'Contact Participant',
  contains: 'Contains',
  context: 'Context',
  continue: 'Continue',
  continueOnWeb: 'Continue on Web',
  copiedToClipBoard: 'Copied to Clipboard!',
  copyChecklistLink: 'Copy Checklist Link',
  copyChecklistLinkToClipboard:
    'Copy a link to this checklist to the clipboard',
  copyLink: 'Copy Link',
  copyPermitLink: 'Copy Permit Link',
  copyPermitLinkToClipboard: 'Copy a link to this permit to the clipboard',
  copyToClipboard: 'Copy to Clipboard',
  couldNotLoadSignature: 'Could not load signature.',
  couldNotRetrieveEmail: 'Could not retrieve email. Please try again',
  countdown: 'Countdown',
  countdownDescription:
    'The Requester will have to wait for the countdown to finish before they can finish post permit checks.',
  countdownName: 'Countdown Name',
  countdownText:
    'Set a period of time which must pass before post permit checks can be complete.',
  countdownTime: 'Countdown Time',
  create: 'Create',
  createAuthGroup: 'Create Authorisation Group',
  createBusinessUnit: 'Create Business Unit',
  createCheck: 'Create Check',
  createChecklist: 'Create Checklist',
  created: 'Created',
  createdBy: 'Created by',
  createdChecklistTemplate: 'Checklist Template Created',
  createdChecklistVersion: 'Checklist Version Created',
  createdDate: 'Created Date',
  createdOn: 'Created On',
  createdOnAscending: 'Created (oldest first)',
  createdOnDescending: 'Created (newest first)',
  createdPermitTemplate: 'Permit Template Created',
  createdPermitTemplateVersion: 'Permit Version Created',
  createGroup: 'Create Group',
  createNewChecklist: 'Create New Checklist',
  createNewChecklistDescription: 'Start a new checklist',
  createNewDraft: 'Create New Draft',
  createNewPermit: 'Create New Permit',
  createNewPermitDescription: 'Start a new permit',
  createNewTag: 'Create new tag',
  createNewTask: 'Create New Task',
  createNewTemplateRole: 'Create New Role',
  createNewVersion: 'Create New Version',
  createNewView: 'Create New View',
  createOrganisation: 'Create Organisation',
  createPasswordInfoText: 'You need to create a password before you can login.',
  createPasswordWelcomeText:
    'Welcome to SiteAssist, please create your account password',
  createPermit: 'Create Permit',
  createPermitRole: 'Create Permit Role',
  createRegister: 'Create Register',
  createRequestPermit: 'Sign & Request',
  createRequestTeamPermit: 'Request',
  createSupportUser: 'Create Support User',
  createTemplate: 'Create Template',
  createZonesNow: 'Create Zones Now',
  createZonesNowDescription:
    'You do not need to set up all of your zones now, you can save progress and finish another time.',
  Creator: 'Creator',
  CSVImport: 'CSV Import',
  csvImportTutorial: 'CSV Import Tutorial',
  csvTemplateDownloaded: 'CSV Template Downloaded',
  currentUser: 'Current User',
  currentVersion: 'Current Version',
  customColours: 'Custom colours',
  dashboard: 'Dashboard',
  date: 'Date',
  dateAndTime: 'Date & Time',
  dateCreated: 'Date Created',
  dateGranularity: 'Date Granularity',
  dateRaised: 'Date Raised',
  dateRange: 'Date Range',
  datesSelected: 'Date(s) Selected',
  dateUploaded: 'Date Uploaded',
  day: 'Day',
  days: 'Days',
  daysShort: 'd',
  deactivate: 'Deactivate',
  deactivated: 'Deactivated',
  declareWorkComplete: 'Declare Work Complete',
  declareWorkCompleteParagraph:
    'Are you sure works are complete? If you proceed, your permit will no longer be open.',
  decline: 'Decline',
  declineTransfer: 'Decline Transfer',
  defaultApproverConfirmationText:
    'I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit.&nbsp;',
  defaultErrorMessage: 'An unexpected error has occurred',
  defaultFilter: 'Default Filter',
  defaultRequestorConfirmationText:
    'By signing this I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit and the permit is now closed.',
  defaultValue: 'Default Value',
  defaultValues: 'Default Values',
  defaultView: 'Default View',
  delete: 'Delete',
  deleteApprovalMessage: 'Are you sure you want to delete this approval?',
  deleteCheck: 'Delete Check',
  deleteChecklistConfirmation:
    'Are you sure you want to remove this checklist?',
  deleted: 'Deleted',
  deletedPermitTemplateVersion: 'Permit Version Deleted',
  deleteDraft: 'Delete Draft',
  deleteDraftMessage: 'Are you sure you want to delete this draft?',
  deleteFileConfirmation: 'Delete File Confirmation',
  deleteFileMessage: 'Are you sure you want to delete this file?',
  deleteFolder: 'Delete Folder',
  deleteFolderConfirmation: 'Delete Folder Confirmation',
  deleteFolderMessage: 'Are you sure you want to delete this folder?',
  deleteGroupPermissionMessage:
    'Are you sure you want to remove this permission?',
  deleteMutationAlertMessage:
    'This will delete this item permanently. Would you like to continue',
  deleteMutationAlertTitle: 'Clear unsynced item',
  deletePlan: 'Delete Plan',
  deletePlanMessage: 'Are you sure you want to delete this plan?',
  deleteProcessTagMessage: 'Are you sure you want to delete this tag?',
  deleteRole: 'Delete Role',
  deleteRule: 'Delete Rule',
  deleteTask: 'Delete Task',
  deleteTaskConfirmation: (taskName: string) =>
    `Are you sure you want to delete ${` "${taskName}"` || 'this task'}?`,
  deleteValidation: 'Delete Validation',
  deleteValidationMessage: 'Are you sure you want to delete this validation?',
  deleteVersion: 'Delete Version',
  deleteView: 'Delete View',
  deleteWarningConfirmation: 'Are you sure you want to delete this warning?',
  deleteZone: 'Delete Zone',
  delimiter: 'Delimiter',
  description: 'Description',
  deselectAll: 'Deselect All',
  destinationOrganisation: 'Destination Organisation',
  details: 'Details',
  detailsAndUsers: 'Details and Users',
  developer: 'Developer',
  diagram: 'Diagram',
  digitalSignature: 'Digital signature',
  digitalSignatureInfo:
    'A Signature is required to Issue Permits. Once added it will be saved to your Profile.',
  disable: 'Disable',
  disabled: 'Disabled',
  disabledOrganisationMember: 'Disabled Organisation Member',
  disabledProjectMember: 'Disabled Project Member',
  disableLocationHierarchy: 'Disable Location Hierarchy',
  disableUser: 'Disable User',
  disableUserOrganisationWarning:
    'This will disable the user within the whole Organisation.',
  disableUserWarning: 'This will disable the user within this Project ONLY.',
  discardDraft: 'Discard Draft',
  discarded: 'Discarded',
  discardedChecklistVersion: 'Checklist Version Discarded',
  discardExtensionRequest: 'Discard Extension Request',
  disclaimer: 'Disclaimer',
  disclaimerDescription:
    "This is the message that will show to a <span class='font-weight-bold'>{0}</span> once the work is complete.",
  distanceToPermit: 'Distance to Permit',
  distanceToPointOfWork: 'Distance to Point of Work',
  doChecklistsRequireGeolocation: 'Do Checklists Require Geolocation?',
  documentAcknowledged: 'Document Acknowledged',
  documentAdded: 'Document added',
  documentName: 'Document name',
  documentOptional: 'Document Optional',
  documentProof: 'Document Proof',
  documentRequired: 'Document Required',
  documents: 'Documents',
  doesNotRequireApprovalDescription:
    'Does not require Approval. If you want to require approval please go to the settings tab.',
  done: 'Done',
  doPeriodicCheck: 'Do New Check',
  doPreStartTasks: 'Do Pre-Start Tasks',
  download: 'Download',
  downloadChecklist: 'Download Checklist',
  downloadComplete: 'Download Complete',
  downloadCSV: 'Download CSV',
  downloadCsvTemplate: 'Download CSV Template',
  downloadFailed: 'Download failed',
  downloadingChecklist: 'Downloading Checklist',
  downloadingPermit: 'Downloading Permit',
  downloadingReport: 'Downloading Report',
  downloadPermit: 'Download Permit',
  draft: 'Draft',
  draftCreated: 'Draft Created',
  draftDeleted: 'Draft Deleted',
  draftIsOutdated: 'Draft is Outdated',
  draftIsOutdatedDescription:
    'This draft is not the latest version of the template and cannot be submitted',
  draftName: 'Draft Name',
  draftNote: 'Draft Note',
  draftNoteTitle:
    'You can add a note to this draft permit request to make it easier to identify.',
  drafts: 'Drafts',
  draftVersionCreated: 'Draft Version Created',
  dragCsvFileToUpload: 'Drag CSV file to upload',
  draw: 'Draw',
  drawNewArea: 'Draw New Area',
  drawOnMap: 'Draw on Map',
  drawOnMapRequiredErrorMessage: 'Please draw your location on the map',
  duplicatedImageDetected: 'Duplicated Image Detected',
  duplicatedImageDetectedModalDescription:
    'We’ve detected that this image had been used before on other permits listed below',
  duplicateImagesDetected: 'Duplicate Images Detected',
  duplicateZoneDescription: 'Zone name is duplicated',
  duplicateZoneModalDescription:
    'You have 2 or more zones with the same name, zone labels should be unique. Please edit this before saving and exiting.',
  duplicateZones: 'Duplicate Zones',
  duration: 'Duration',
  durationMinutes: 'Duration (Minutes)',
  dynamicNoSearchResults: (searchedItem: string) =>
    `Could not find any ${searchedItem} against the search query`,
  edit: 'Edit',
  editAnnotation: 'Edit Annotation',
  editAnnotations: 'Edit Annotations',
  editApproval: 'Edit Approval',
  editAttachments: 'Edit Attachments',
  editBusinessUnit: 'Edit Business Unit',
  editConditions: 'Edit Conditions',
  editDueDate: 'Edit Due Date',
  editFile: 'Edit File',
  editFinalSignoff: 'Edit Final Signoff',
  editFolder: 'Edit Folder',
  editInMap: 'Edit in Map',
  editItem: 'Edit Item',
  editMember: 'Edit Member',
  editOrganisation: 'Edit Organisation',
  editParticipant: 'Edit Participant',
  editPermission: 'Edit Permission',
  editPlan: 'Edit Plan',
  editProfile: 'Edit Profile',
  editQuestions: 'Edit Questions',
  editRegister: 'Edit Register',
  editRole: 'Edit Role',
  editTag: 'Edit Tag',
  editTask: 'Edit Task',
  editTemplateName: 'Edit Template Name',
  editTemplates: 'Edit Templates',
  editThisZone: 'Edit this Zone',
  editViewName: 'Edit View Name',
  editWarning: 'Edit Warning',
  editYourInformation: 'Edit your personal profile information',
  ellipse: 'Ellipse',
  email: 'Email',
  emailAddress: 'Email',
  emailIsRequired: 'Email address is required',
  emailMustBeValid: 'Email must be valid',
  emailNotificationSent: 'Email Notification Sent',
  emptyFolder: 'This folder is empty',
  emptyFolderMessage:
    'You can upload documents or photos by tapping on the plus button in the top right corner',
  emptyQuestionsMessage:
    'You cannot activate this version without <span class="text-bold">{0} Questions</span>. Please add questions to the relevant section{1} before activating this version.',
  enable: 'Enable',
  enabled: 'Enabled',
  enableDiagramDescription:
    'Enable diagrams to allow showing and manipulating register items on a plan or document.',
  enableDiagrams: 'Enable diagrams',
  enableDuration: 'Enabling this will enforce a maximum duration for Permits.',
  enableLive: 'Enable (Live)',
  enableLocationHierarchy: 'Enable Location Hierarchy',
  enablePermitMaximumDuration: 'Enable Permit Maximum Duration',
  enablePermitRequestExtension: 'Enable permit request extension',
  enablePermitShorten: 'Enable shorten permit',
  enablePermitSuspension: 'Enable permit suspension',
  enablePermitTransfer: 'Enable permit holder transfer',
  enableProjectLocations: 'Enable Project Locations',
  end: 'End',
  endDate: 'End date',
  endDateAndTime: 'End date & time',
  ended: 'Ended',
  ends: 'Ends',
  endsOnAscending: 'Ends (oldest first)',
  endsOnDescending: 'Ends (newest first)',
  endTime: 'End Time',
  ensureMaxDuration:
    'Please create permits at least 30 minutes into the future from the start time',
  enterAddress: 'Enter address',
  enterAnswer: 'Enter Answer',
  enterBasicGroupInfo: 'Enter some basic information about the group',
  enterCity: 'Enter City',
  enterCompany: 'Enter Company',
  enterDocumentName: 'Enter Document Name',
  enterEmail: 'Enter Email',
  enterFirstName: 'Enter First Name',
  enterLastName: 'Enter Last Name',
  enterName: 'Enter Name',
  enterNote: 'Enter Note',
  enterNumber: 'Enter Number',
  enterPhone: 'Enter Phone',
  enterPhoneNumber: 'Enter phone number',
  enterPostcode: 'Enter postcode',
  enterReason: 'Enter Reason',
  enterRequestChanges:
    'E.g. Please provide clearer image of the signage for question 1',
  enterTime: 'Enter Time',
  errorMessage: 'Error Message',
  errorMessage400: 'Request failed with status code 400',
  errorMessage401: 'Request failed with status code 401',
  errorMessage404: 'Not Found',
  errorMessage500: 'Request failed with status code 500',
  errorPleaseTryAgain: 'Error (please try again)',
  errorUploading: 'Error uploading',
  excludeWeekends: 'Exclude Weekends',
  exit: 'Exit',
  exitWithoutSaving: 'Exit without saving?',
  expiration: 'Expiration',
  expired: 'Expired',
  expiresIn: 'Expires In',
  expiryDate: 'Expiry Date',
  exports: 'Exports',
  exportSuccessMessage: 'Success! Click Download to download the export.',
  extend: 'Extend',
  extendedDate: 'Extended Date',
  extendEndTimeError: 'Please choose a time after the end time of the permit',
  extendPermit: 'Extend Permit',
  extendRejectDescription:
    'If you want to reject the extension please provide a reason.',
  extensionApproved: 'Extension Approved',
  extensionInformation: 'Extension Information',
  extensionReason: 'Extension Reason',
  extensionRejected: 'Extension Rejected',
  extensionRequestDisclaimer:
    'By signing this you acknowledge your responsibility as the current Permit Holder and agree to approved the extension request.',
  extensionRequested: 'Extension Requested',
  extensionRequestedDetails: 'Extension Request Details',
  extensionsRequested: 'Extension Requested',
  extensionsReviewTitle: 'Who can review Extensions?',
  external: 'External',
  externalUser: 'External User',
  feedbackSubmitted: 'Feedback submitted',
  fetchingOrganisationUser: 'fetching user details...',
  fetchingProjectUser: 'fetching user details...',
  file: 'File',
  fileArchived: 'File archived',
  fileDownloaded: 'File downloaded',
  fileFormatInvalid: 'File Format Invalid',
  filePreview: 'File Preview',
  fileReadAccess: 'File Read access',
  fileReadAccessMessageProof:
    'SiteAssist needs file read access to upload proofs',
  fileReadAccessMessageSignature:
    'SiteAssist needs file read access to save your signature',
  fileRenamed: 'File renamed',
  fileRestored: 'File restored',
  fileSize: 'This file is too big',
  fileWriteAccess: 'File write access',
  fileWriteAccessMessage:
    'SiteAssist needs file write access to save your signature',
  fillOutAllQuestions: 'Please fill out all questions',
  filter: 'Filter',
  filterPermits: 'Filter Permits',
  filterRegisters: 'Filter Registers',
  filters: 'Filters',
  filtersApplied: 'Filters Applied',
  filterValue: 'Filter Value',
  finalSignOff: 'Final Sign Off',
  finalSignoff: 'Final Signoff',
  finalSignOffChecks: 'Final Sign Off Checks',
  finalSignOffDisclaimerDescription:
    "This is the message that will show to the <span class='font-weight-bold'>Approver</span> once the work is complete.",
  finalSignOffDisclaimerText:
    'By signing this I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit and the permit is now closed.',
  finalSignOffDisclaimerTitle: 'Final Sign Off Disclaimer',
  finalSignOffLabel: 'Final SignOff Label',
  finalSignOffQuestions: 'Final SignOff Questions',
  finalSignOffRequested: 'Final sign-off requested',
  finalSignOffs: 'Final SignOffs',
  finalSignoffSubtitle:
    'If your template needs questions to be reviewed by an auth group before, please select.',
  finished: 'Finished',
  fireWatchDescription:
    'This means the permit can be closed {0} at the earliest.',
  fireWatchTitle: 'There is a {0} minute {1} countdown after expiry time',
  firstName: 'First Name',
  firstNameRequired: 'First name is a required field',
  folder: 'Folder',
  folderCreated: 'Folder created',
  folderInformation: 'Folder Information',
  folderMoved: 'Folder moved',
  folderPermissionsMissing: 'Required file access permissions missing',
  folderRenamed: 'Folder renamed',
  for: 'For',
  forgotPassword: 'Forgot Password',
  forgotPasswordLinkSent: 'Forget Password Link Sent',
  formula: 'Formula',
  formulasRecalculated: 'Formulas Recalculated',
  forThisRegisterNewItemsNeedToBeCreatedInTheDiagramView:
    'For this register, new items need to be created in the diagram view.',
  fromCameraOrGallery: 'from camera or gallery',
  fullScreen: 'Full Screen',
  gatewayTimeoutError: 'API is unreachable right now',
  general: 'General',
  generalApproval: 'General Approval',
  generalInformation: 'General information',
  generalInformationPlaceholder:
    'Enter any useful information about this project',
  generalPermissions: 'General Permissions',
  generateChecklistPdf: 'Download Checklist PDF',
  generateChecklistPdfDescription: 'Export this checklist as a PDF file',
  generatePermitPDF: 'Download Permit PDF',
  generatePermitPDFDescription: 'Export this permit as a PDF file',
  generating: 'Generating...',
  genericError: 'Error processing request',
  genericProjectPlanError:
    'Something went wrong while initalizing the project plan',
  geoLocation: 'Geolocation',
  geoLocationInputType: 'What is the geolocation input type?',
  getTotalDrawnAreaSize: (squareMeters: number) =>
    `Total drawn area size: ${squareMeters} m²`,
  globalSettings: 'Global Settings',
  globalWarnings: 'Global Warnings',
  goBack: 'Go back',
  goToDetails: 'Go To Details',
  groupCreated: 'Group Created',
  groupCreatedSuccessfully: 'Group created successfully',
  groupDeleted: 'Group Deleted',
  groupDetails: 'Group Details',
  groupName: 'Group Name',
  groups: 'Groups',
  groupSkipped: '(Group Skipped)',
  hasNotBeenDone: 'Has not been done',
  hasPermitLinks: 'Has Permit Links',
  hazardDetected: 'Hazard Detected',
  help: 'Help',
  hi: 'Hi',
  hide: 'Hide',
  hideAll: 'Hide All',
  hideGroupIfAnswer: 'Hide Group if answer is:',
  hideIfAnswerIs: 'Hide if answer is',
  hideInfo: 'Hide Info',
  hideLocation: 'Hide Location',
  hidePhotos: 'Hide Photos',
  hideSignature: 'Hide Signature',
  hideTestProjects: 'Hide Test Projects',
  home: 'Home',
  hour: 'Hour',
  hours: 'Hours',
  hoursShort: 'h',
  howToGuide: '`How` to guide',
  identifierProperty: 'Identifier Property',
  identifierPropertyDescription:
    'Choose which property is used to identify items on the plan (usually a name).',
  ifAnswer: 'If answer',
  ifYouExitWithoutSaving:
    'If you exit without saving you will lose your zones. Save now?',
  image: 'Image',
  imageObjectDetection: 'Image Object Detection',
  impersonateUser: 'Impersonate User',
  import: 'Import',
  important: 'Important!',
  importViaCsv: 'Import via CSV',
  incrementalId: 'Incremental ID',
  incrementByParent: 'Increment by Parent',
  info: 'Info',
  initiated: 'Initiated',
  initiatedBy: 'Permit Author',
  initiateTransfer: 'Initiate Transfer',
  initiatorRole: 'Initiator Role',
  initiatorRoleQuestion: 'Who starts the process?',
  initiatorRoleText:
    'If your template needs questions to be reviewed by an auth group before, please select.',
  insertLabel: 'Insert Label',
  inspection: 'Inspection',
  inspectionDone: 'Inspection Done',
  inspectionDue: 'Inspection Due',
  inspectionExiryDate: 'Inspection Expiry Date',
  inspectionExpired: 'Inspection Expired',
  inspections: 'Inspections',
  inspectionType: 'Inspection Type',
  installMobileApp: 'Install Mobile App',
  intendedRecepient: 'Intended recepient',
  intendedRecipient: 'Intended Recipient',
  invalidAnswer: 'Invalid Answer',
  invalidAnswerDescription: 'Invalid Answer Description',
  invalidAnswerExplanation:
    'Sorry, this Permit Request cannot be processed if the answer to this question is',
  invalidAnswerOption: 'Enter An Invalid Answer Option',
  invalidPermissions: 'Invalid Permissions',
  invalidW3W: 'Invalid what3words address',
  invite: 'Invite',
  inviteSent: 'Invite sent',
  inviteUser: 'Invite User',
  inviteUserToProject: 'Invite User to Project',
  ios: 'IOS',
  isChecklistOwnedByRegister: 'Is checklist owned by a register item?',
  isDue: 'Is due',
  isDueInLessThan: 'Is due in less than',
  isNotPresent: 'Is Not Present',
  isPresent: 'Is Present',
  issue: 'Issue',
  issued: 'Issued',
  issuedBy: 'Issued by',
  issueDescription: 'Issue the permit to begin work on site',
  issueNewPermit: 'Issue a new permit',
  issuePermit: 'Issue Permit',
  itemAdded: 'Item Added',
  itemArchived: 'Item Archived',
  itemCreated: 'Item Created',
  itemCreatedViaCsv: 'Item Created via CSV',
  itemDetails: 'Item Details',
  itemPermissions: 'Item Permissions',
  items: 'Items',
  itemUnarchived: 'Item Unarchived',
  itemUpdated: 'Item Updated',
  itemUpdatedViaCsv: 'Item Updated via CSV',
  labels: 'Labels',
  lastActive: 'Last active',
  lastBriefed: 'Last Briefed',
  lastCheck: 'Last Check',
  lastCompleted: 'Last Completed',
  lastModified: 'Last Modified',
  lastName: 'Last Name',
  lastNameRequired: 'Last name is a required field',
  lastTwoWeeks: 'Last 2 weeks',
  lastUpdated: 'Last Updated',
  leave: 'Leave',
  link: 'Link',
  linkedDocuments: 'Linked Documents',
  linkedOrganisations: 'Linked Organisations',
  linkExpiry: 'Link expires in 60 minutes',
  live: 'Live',
  liveTemplateDescription:
    'Making this template live will allow this template to be used in permit requests.',
  loading: 'Loading',
  location: 'Location',
  locationArchiveConfirmationText: `By archiving, you will no longer be able to edit. However, you can
  still view (read-only) archived items by selecting 'Archived' in the
  Status Filter.`,
  locationArchived: 'Location Archived',
  locationCheckIn: 'Location Check In',
  locationDisableConfirmationMessage:
    'Disabling Location Hierarchy for this project will hide these changes. This will disable visibility of Location Hierarchy for all those working on this project.',
  locationEnableConfirmationMessage:
    'Enabling Location Hierarchy for this project will push these changes to live. This will enable all those working on this project to see these changes.',
  locationHierarchyDisabled: 'Location Hierarchy Disabled',
  locationHierarchyEnabled: 'Location Hierarchy Enabled',
  locationLoading: 'Location Loading ...',
  locationNotUpdatedRecently: 'Location not updated recently, please try again',
  locationOfWorks: 'Location of Works',
  locationOptions: 'Location Options',
  locationRenamed: 'Location Renamed',
  locationsHierarchy: 'Locations Hierarchy',
  locationsSaved: 'Locations Saved',
  lockedAfterCreationField: 'Locked after creation Field',
  loggedInAs: 'Logged in as',
  loggingIn: 'Logging in',
  login: 'Log in',
  logo: 'Logo',
  logoOptional: 'Logo (Optional)',
  logout: 'Logout',
  logoutMessage: 'You will be returned to the login screen.',
  m2: ' m²',
  mainContact: 'Main Contact',
  maintenance: 'Maintenance',
  maintenanceDescription:
    'We are currently under maintenance, we will be back soon',
  manageMembers: 'Manage Members',
  managePermitProjectConfiguration: 'Manage Permit Project Configuration',
  map: 'Map',
  mapBoxStyleUrl: 'MapBox Style URL',
  mapCenterLatitude: 'Map Center Latitude',
  mapCenterLongitude: 'Map Center Longitude',
  mappingConfigurationSaved: 'Mapping Configuration Saved',
  mapPOC: 'Map POC',
  mapZoom: 'Map Zoom',
  markAllAsRead: 'Mark all as read',
  max: 'Max',
  max20Letters: 'Max 20 letters',
  maxDurationInfo:
    'This means the permit end date must be within this timeframe.',
  maxFileSizeExceeded: 'File size is above the limit of {0}mb',
  maximumDuration: 'Maximum Duration',
  maximumExpiryTime: 'Maximum Expiry Time',
  maximumRowsCSVExportError: 'Maximum of 50,000 rows can be downloaded',
  maxSize15mb: 'Max size 15mb',
  memberAdded: 'Member added',
  memberRemoved: 'Member removed',
  members: 'Members',
  membersUpdated: 'Members Updated',
  metricName: 'Metric Name',
  min: 'Min',
  minimise: 'Minimise',
  minsShort: 'm',
  minutes: 'Minutes',
  missingToken: 'Missing token',
  modified: 'Modified',
  monthsShort: 'm',
  moreActions: 'More Actions',
  move: 'Move',
  movedChecklistTemplate: 'Checklist Template Moved',
  movedChecklistVersionToDraft: 'Checklist Version Moved to Draft',
  moveDown: 'Move Down',
  movedPermitTemplate: 'Moved Permit Template',
  movedPermitTemplateVersionToDraft: 'Permit Version Moved to Draft',
  moveTemplate: 'Move Template',
  moveUp: 'Move Up',
  multipleFinalSignoffs: 'Multiple Final Sign-Offs',
  multipleLocations: 'Multiple Locations',
  multipleProjects: 'Multiple Projects',
  multipleSelected: 'Multiple selected',
  multipleStatuses: 'Multiple Statuses',
  multipleTemplates: 'Multiple Templates',
  mustBeCompletedOnMobile: 'Must be completed on mobile device.',
  mustBePartOfProjectChecklistCreate:
    'You must be added to a project prior to creating a checklist. Please contact your project admin to be added to the project.',
  mustBePartOfProjectPermitCreate:
    'You must be added to a project prior to creating a permit. Please contact your project admin to be added to the project.',
  mustUploadProof: 'You must upload a proof for this question',
  mustUploadSignature: 'You must upload your signature for this question',
  myChecklists: 'My Checklists',
  myPermits: 'My Permits',
  myProjects: 'My Projects',
  myRole: 'My Role',
  na: 'N/A',
  name: 'Name',
  nameIsRequired: 'Name is required',
  nameOfGroup: 'Name of the group',
  nameOfProject: 'Name of the project',
  networkError: 'Network error. Please check your connection and try again.',
  never: 'Never',
  new: 'New',
  newApproval: 'New Approval',
  newChecklist: 'New Checklist',
  newComment: 'New Comment',
  newEndDateAndTime: 'New End Date & Time',
  newEndTimeFrom: 'New end time from:',
  newestFirst: 'Newest First',
  newFilter: 'New Filter',
  newFinalSignoff: 'New Final Signoff',
  newFolder: 'New Folder',
  newInspection: 'New Inspection',
  newLocation: 'New Location',
  newPassword: 'New password',
  newPeriodicCheck: 'New periodic check',
  newPermissions: 'New Permissions',
  newPermit: 'New Permit',
  newRole: 'New Role',
  newStep: 'New Step',
  newTag: 'New Tag',
  newTask: 'New Task',
  newTemplate: 'New Template',
  newUser: 'New User',
  newUserInvitedToProjectPermissionsInfo:
    'If the user needs additional permissions, please ask your Project Admin or get in touch with us',
  newValidation: 'New Validation',
  newView: 'New View',
  next: 'Next',
  nextInspectionDue: 'Next Inspection Due',
  nextNominatedApprover: 'Next Nominated Approver',
  no: 'No',
  noAccessToProjectMessage:
    'You are not a member of this project.\nPlease speak to someone to add you to the project.',
  noAuthorisedPersonsFound:
    '<p>No Permit Approvers found in this project. Try <a class="text-link">adding Permit Approvers</a> to your project, before adding them to a group</p>',
  noAvailableActions: 'No available actions',
  noAvailablePropertyComponents: 'No available property components',
  noBusinessUnitsFound: 'Could not find any business units',
  noChecklists: 'No checklists were found',
  noChecklistsFound: 'No checklists found',
  noChecklistsFoundProject:
    '<p>No checklists found for this project. Try <a class="text-link">adding Checklist Templates</a> to your project, before adding them to a group</p>',
  noChecklistText: 'No checklists found.',
  noChecksSubmitted: 'No checks submitted to date',
  noColours: 'No colours',
  noDateSelected: 'No date selected',
  noDraftsFound: 'No drafts found',
  noEntitiesFound: (entityType: string) => `No ${entityType}s were found`,
  noEvents: 'There are no events for this permit yet',
  noExit: 'No, exit',
  noFilesFound: 'No files found',
  noFilter: 'No Filter',
  noItemsFound: 'No items found',
  nominateAnApprover: 'Nominate an Approver',
  nominateAnApproverText:
    'If you would like to nominate a specific approver, select from the dropdown below. Otherwise, click Skip.',
  noMoreResults: 'No more results.',
  noMoreTemplates: 'No more templates available',
  none: 'None',
  noNotifications: 'No notifications',
  noOfInspectionsHaveExpired: (noOfExpiredInspections: number) =>
    `${noOfExpiredInspections}${
      noOfExpiredInspections > 1
        ? ' Inspections have expired'
        : ' Inspection has expired'
    }`,
  noOfOtherPropertiesHidden: (n: number) => `${n} other properties hidden ...`,
  noOptions: 'No Options',
  noOptionsFound: 'No options found',
  noOrganisationDescription:
    'You are not part of any organisations. This could be because you have been removed by an administrator. Please speak to us if you think a mistake was made.',
  noOtherParticipants: 'No other participants',
  noParticipantsFound: 'No participants found',
  noParticipantTimelineEntries: 'Nothing to show for this participant',
  noPeriodicChecksText: "You don't have any Periodic Checks configured",
  noPermissionProjectModifyAuthGroups: (groupNames: string) =>
    `Only people in the following groups can edit: ${groupNames}`,
  noPermissions: 'No Permissions',
  noPermissionsSet: 'No permissions set',
  noPermitRoleText: 'No permit roles found.',
  noPermits: 'No permits were found',
  noPermitsFound:
    '<p>No permits found for this project. Try <a class="text-link">adding Permit Templates</a> to your project, before adding them to a group</p>',
  noPermitTypesAvailable: '“No permit types available',
  noPlans: 'No Plans',
  noPlansDescription:
    'No technical plans have been uploaded yet. To upload a new plan, first create a location (via Locations Hierarchy) and upload a plan for that location.',
  noPlansDescription2:
    "View our 'How to' guide for more help, or go to Locations hierarchy now to upload a plan.",
  noPreStartTasksFound: 'You don’t have any tasks configured.',
  noPreStartTaskText: 'No pre-start tasks found.',
  noProjectsDashboard:
    'You must be added to a live project to view the organisation dashboard. Please contact your Project Admin to be added to a project. If you are part of test projects you can see their dashboard in the project page.',
  noProjectsWithProjectAdminRole:
    'You are not a Project Admin in any Projects.',
  noProofsProvided: 'No proofs provided',
  noPropertyHasBeenAddedYet: 'No property has been added yet',
  noQuestionsText: 'No questions found.',
  noReasonProvided: 'No reason provided',
  noRegistersFound: 'No registers found',
  noResultsFound: 'No results were found',
  noResultsFoundPleaseChangeSearch:
    'No results were found, please change search',
  noRuleText: 'There is no rules associated with this question.',
  noSearchResults: 'No results found',
  noTagsFound: 'No tags found',
  noTasksFound: 'There are no outstanding tasks',
  note: 'Note',
  noTemplatesFound: 'No templates found',
  notFound: 'Not Found',
  noThanks: 'No, thanks',
  nothingSelected: 'Nothing selected',
  notifications: 'Notifications',
  notPinned: 'Not Pinned',
  notRequired: 'Not Required',
  noUnreadNotifications: 'No unread notifications',
  noUserFound: 'No user found',
  noUsersFound: 'No users found',
  noVersions: 'There are no versions for this Template',
  noVersionsFound: 'No versions found',
  nTasksOutstanding: (n: number) =>
    `${n} Task${n === 1 ? '' : 's'} Outstanding`,
  numberOfAnswers: 'Number of Answers',
  numberOfDays: 'No. of days',
  numberOfRules: (n: number) =>
    `Conditional Logic: ${n} Rule${n === 1 ? '' : 's'}`,
  numberOfTotalItemsSelected: (number: number, total: number) =>
    `${number} of ${total} Item${total > 1 ? 's' : ''} selected`,
  NumberShort: 'No.',
  numChecklists: (n: number) => `${n} Checklist${n === 1 ? '' : 's'}`,
  numeric: 'Numeric',
  numLinkedDocuments: (n: number) =>
    `${n} Linked Document${n === 1 ? '' : 's'}`,
  numTasks: (n: number) => `${n} Task${n === 1 ? '' : 's'}`,
  nUnsyncedItems: (n: number) => `${n} Unsynced Item${n === 1 ? '' : 's'}`,
  objectDetectionWarningModalDescription:
    'We’ve detected that this image contains a potential hazard',
  offlineActionsSynced: 'All offline actions synced',
  ok: 'OK',
  okay: 'Okay',
  oldestFirst: 'Oldest First',
  oldPermissions: 'Old Permissions',
  onlyFromCamera: 'only from camera',
  open: 'Open',
  openAllComments: 'Open All Comments',
  openAllQuestionComments: 'Open All Question Comments',
  openChecklist: 'Open Checklist',
  opened: 'Opened',
  openInBrowser: 'Open in Browser',
  openInNewTab: 'Open in new tab',
  openItemInNewTab: 'Open Item in new tab',
  openLinkedItem: 'Open linked item',
  openMap: 'Open Map',
  openMobileApp: 'Open Mobile App',
  openPermit: 'Open Permit',
  openShape: 'Open Shape Detected',
  openShapeModalDescription:
    'Please close the polygon shape before proceeding (by pressing the "tick icon")',
  openToRetry: 'loading failed. Retry',
  operative: 'Operative',
  operativeInfo:
    'Standard Users can create permits and checklists, and view project data',
  operativeTooltip:
    'Can request an individual permit using the mobile application as well as view all open permits related to them.',
  operator: 'Operator',
  optional: 'Optional',
  optionalField: 'Optional Field',
  optionalProperty: 'Optional Property',
  options: 'Options',
  or: 'or',
  orAddParticipantsManually: 'Or, Add Participants Manually',
  orderChanged: 'Order Changed',
  organisation: 'Organisation',
  organisationCreated: 'Organisation Created',
  organisationLinkType: 'Link Type',
  organisationName: 'Organisation Name',
  organisationOverview: 'Organisation Overview',
  organisationPermissions: 'Organisation Permissions',
  organisations: 'Organisations',
  organisationTemplate: 'Organisation Template',
  organisationUpdatedSuccessfully: 'Organisation Updated Successfully',
  otherParticipants: 'Other Participants',
  override: 'Override',
  overrideFormulaValue: 'Override Formula Value',
  overrideRegisterItemFormulaWarning:
    'This will override the value calculated by this formula and LOCK it to the value you set.',
  overview: 'Overview',
  ownedByAnotherOrg: 'Owned by another organisation',
  ownedByOrg: (orgName: string) => `Owned by ${orgName}`,
  padToNumberOfCharacters: 'Pad to number of characters',
  participant: 'Participant',
  participantAdded: 'Participant added',
  participantBriefed: 'Participant Briefed',
  participantInformation: 'Participant Information',
  participantLog: 'Participant Log',
  participantRemoved: 'Participant Removed',
  participants: 'Participants',
  participantsBriefed: 'Participants Briefed',
  participantsCreated: 'Participants Created',
  participantSigned: 'Participant Signed',
  password: 'Password',
  passwordRequired: 'Password is required',
  passwordResetSuccess: 'Password reset successfully!',
  passwordResetSuccessNextAction:
    'You can now log into the web and mobile apps with your new password',
  passwordsMustMatch: 'Passwords must match',
  pasteAnswers: 'Paste answers',
  pasteOptions: 'Paste Multiple Choice Options',
  pasteOptionsDescription:
    'Paste your options in the field below. Each option should be on a new line.',
  pasteOptionsPlaceholder: 'Paste options here',
  pdf: 'Pdf',
  pdfEditor: 'PDF Editor',
  pdfViewer: 'PDF Viewer',
  pending: 'Pending, (You have signed)',
  people: 'People',
  performChecks: 'Perform Checks',
  performChecksDescription:
    'Do Post Permit Checks so that it can go to Final Sign-off',
  periodicCheck: 'Periodic Check',
  periodicCheckDeleted: 'Periodic Check Deleted',
  periodicChecks: 'Periodic Checks',
  periodicCheckSaved: 'Periodic Check Saved',
  periodicCheckSubmitted: 'Periodic Check Submitted',
  periodicCheckTitle: 'Periodic Check Title',
  permissionLevel: 'Permission Level',
  permissions: 'Permissions',
  permissionsUpdated: 'Permissions Updated',
  permissionUpdate: 'Permission Update',
  permit: 'Permit',
  permitActions: 'Permit Actions',
  permitApprove: 'Permit Approve',
  permitApproved: 'Permit approved',
  permitApprover: 'Permit Approver',
  permitApproverFilter: 'Permit Approver',
  permitApproverTooltip:
    'Can create an approver permits based on their Authorisation Groups',
  permitAreas: 'Permit Areas',
  permitAssignedToYou: 'Permit assigned to you',
  permitCancelled: 'Permit Cancelled',
  permitCancelledNotifications: 'Permit cancelled',
  permitChangesRequested: 'Permit changes are requested',
  permitChecklists: 'Permit Checklists',
  permitClonable: 'Permit Clonable',
  permitCloned: 'Permit has been cloned',
  permitClosed: 'Permit Closed',
  permitContacts: 'Permit Contacts',
  permitDetails: 'Permit Details',
  permitDiscarded: 'Permit discarded',
  permitDoesntRequireApproval:
    'The permit will be automatically issued after the permit holder creates or receives it',
  permitDownloadComplete: 'Permit Download Complete',
  permitDownloadFailed: 'Permit Download Failed',
  permitDraftSaved: 'Permit Draft Saved',
  permitDraftUpdated: 'Permit Draft Updated',
  permitDuration: 'Permit Duration',
  permitEnd: 'Permit End',
  permitExtendApproveDeclaration:
    'A request has been made to extend the permit to ',
  permitExtendApproveDeclaration1: 'A request has been made from ',
  permitExtendApproveDeclaration2: ' to extend the permit to ',
  permitExtended: 'Permit Extended',
  permitExtensionApprovedNotifications: 'Permit extension approved',
  permitExtensionRejected: 'Permit Extension Rejected',
  permitExtensionRejectedNotifications: 'Permit extension rejected',
  permitExtensionRequested: 'Permit extension requested',
  permitHasExpired: 'Permit has expired',
  permitHolder: 'Permit Holder',
  permitHolderByDefault: 'Permit Holder (by default)',
  permitHolderChanged: 'Permit Holder Changed',
  permitHolderFilter: 'Permit Holder',
  permitHolderQuestions: 'Permit Holder Questions',
  permitHolderSelfApprove: 'Permit holder self approve',
  permitHolderTel: 'Permit Holder Tel',
  permitHolderTooltip:
    'The Permit Holder will request a permit, and it will be issued after the Permit Approver or Project Admin reviews and approves the request.',
  permitInitiation: 'Permit Initiation',
  permitInitiationPermissionMessage:
    'When this permission is applied, a user must be in an Authorisation Group that has this permission enabled to raise the permit. Any Users not in one of these Auth Groups will not be able to raise this permit template.',
  permitIsNowClosed: 'Permit is now closed',
  permitIsNowOpen: 'Permit is now open',
  permitList: 'Permit List',
  permitMaximumDuration: 'Permit Maximum Duration',
  permitNoPermission: 'You do not have permissions to view this permit',
  permitPdfs: 'Permit Documents',
  permitPermissions: 'Permit Permissions',
  permitQRCodeDescription:
    'Scan this QR code with your phone camera to open the permit.',
  permitQuestions: 'Permit Questions',
  permitReceived: 'Permit Received',
  permitRegister: 'Permit Register',
  permitRegisterDescription:
    'Download a .csv file of this project’s permit register over the selected date range.',
  permitRejected: 'Permit Rejected',
  permitRejectedNotifications: 'Permit rejected',
  permitRequested: 'Permit requested',
  permitRequiresApproval:
    'Before the permit can be opened, it will require approval by someone authorised',
  permitRequiresPostPermitChecks: 'Permit requires post-permit checks',
  permitResumed: 'Permit Resumed',
  permitResumedNotifications: 'Permit resumed',
  permitRole: 'Permit Role',
  permitRoleDeleted: 'Periodic Check Deleted',
  permits: 'Permits',
  permitShortened: 'Permit duration successfully shortened',
  permitSigned: 'Permit Signed',
  permitStart: 'Permit Start',
  permitStatus: 'Permit Status',
  permitSummary: 'Permit Summary',
  permitSummaryLabel: 'Summary',
  permitSummaryPlaceholder: 'e.g. "Location - Works title"',
  permitSuspended: 'Permit Suspended',
  permitSuspendedNotifications: 'Permit suspended',
  permitSuspension: 'Permit Suspension',
  permitTemplate: 'Permit Template',
  permitTemplates: 'Permit Templates',
  permitTemplateVersions: 'Permit Template Versions',
  permitTimeline: 'Permit Timeline',
  permitToDig: 'Permit to Dig',
  permitTransfer: 'Permit Transfer',
  permitTransferAccepted: 'Permit Transfer Accepted',
  permitTransferApproved: 'Permit Transfer Approved',
  permitTransferApprovedNotifications: 'Permit transfer approved',
  permitTransferCancelled: 'Permit Transfer Cancelled',
  permitTransferCancelledNotifications: 'Permit transfer cancelled',
  permitTransferDeclined: 'Permit Transfer Declined',
  permitTransferInitiated: 'Permit Transfer Initiated',
  permitTransferInitiatedNotifications: 'Permit transfer initiated',
  permitTransferRejected: 'Permit Transfer Rejected',
  permitTransferRejectedNotifications: 'Permit transfer rejected',
  permitTransferRequestedNotifications: 'Permit transfer requested',
  permitType: 'Permit Type',
  permitTypes: 'Permit Types',
  permitTypesSelected: 'Permit Types Selected',
  permitUniqueCode: 'Permit Unique Code',
  permitWorkflow: 'Permit workflow',
  permitWorkflowDescription:
    'Configure the optional stages that this permit moves through during its lifecycle.',
  permitWorkflowFinalSignOff: 'Final Sign-Off',
  permitWorkflowFinalSignOffComplete: 'Complete Final Sign-Off',
  permitWorkflowFinalSignOffDescription:
    'This permit requires final sign-off to be closed',
  permitWorkflowPostPermitChecks: 'Post Permit Checks',
  permitWorkflowPostPermitChecksDescription:
    'The Permit Holder has to answer configured questions after work is complete.',
  permitWorkflowRequest: 'Request',
  permitWorkflowRequestDescription:
    'The Permit Holder has to answer configured questions in order to Request a Permit. Project Admins review the answers to questions and decide whether to issue or reject the Permit.',
  phoneRequired: 'Phone is a required field',
  pinItem: 'Pin Item',
  pinOnTheLeft: 'Pin on the left',
  pinOnTheRight: 'Pin on the right',
  plan: 'Plan',
  planDeleted: 'Plan Deleted',
  planName: 'Plan Name',
  planPublished: 'Plan Published',
  plans: 'Plans',
  planSaved: 'Plan Saved',
  planUploaded: 'Plan Uploaded',
  pleaseCompleteAllQuestions: 'Please complete all questions before proceeding',
  pleaseDownloadApp: 'Please download the mobile app',
  pleaseFillAllFieldsCorrrectly: 'Please fill all fields correctly',
  pleasePassDeviceTo: 'Please pass the device to:',
  pleaseProvideNameForThisLevel: 'Please provide a name for this level.',
  pleaseProvideNameForThisLocation: 'Please provide a name for this location.',
  pleaseSeachByNameOrEmail: 'Please search by name or email',
  pleaseSearchByTemplateName: 'Please search by template name',
  pleaseSelect: 'Please Select',
  pleaseSelectAnExpiryTime: 'Please select an expiry time',
  pleaseSelectAnInspection: 'Please select an inspection',
  pleaseSelectATemplate: 'Please select a template',
  pleaseSelectDateRange: 'Please select a date range',
  pleaseSelectTheExpiryTime: 'Please select the expiry time',
  pleaseSetTheExpiryTimeOfTheInspection:
    'Please set the Expiry time of the inspection',
  pleaseSpeakToSiteAssistMappingConfiguration:
    'Please speak to someone from SiteAssist to enable maps for your Project',
  pleaseUpdate: 'Please update the app to continue using SiteAssist.',
  pointOfWorks: 'Point of Works',
  pointOfWorks3Words: 'Point of Works What3Words',
  pointOfWorks3WordsPlaceholder: "eg. '///snow.logic.chains'",
  polygon: 'Polygon',
  postcode: 'Postcode',
  postPermitChecks: 'Post Permit Checks',
  postPermitChecksComplete: 'Post Permit Checks Complete',
  postPermitChecksCountdown: 'Post permit checks countdown',
  postPermitChecksDescription:
    'Post Permit Checks must be done to close the permit.',
  postPermitChecksDisclaimerDescription:
    "This is the message that will show to the <span class='font-weight-bold'>Permit Holder</span> once the work is complete.",
  postPermitChecksDisclaimerForRequesterTitle:
    'Post Permit Checks Disclaimer for Requester',
  postPermitChecksDisclaimerText:
    'I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit.',
  postPermitChecksDisclaimerTitle: 'Post Permit Checks Disclaimer',
  postPermitChecksGeolocationWebTooltip:
    'Post Permit Checks for this permit template must be completed on a mobile device',
  postPermitChecksInfo: 'A participant should perform post permit checks',
  postPermitChecksRequest: 'Post Permit Checks Request',
  postPermitCheckTimeRemainingInfo:
    'Time remaining before the permit holder can perform post permit checks',
  postPermitCountdownFinished: 'Post Permit Checks Countdown Finished',
  postPermitQuestions: 'Post Permit Questions',
  prefix: 'Prefix',
  preparePermit: 'Prepare Permit',
  preStart: 'Pre Start',
  preStartTaskConditionalLogicDescription:
    'Set the conditions that will trigger this Pre-Start Task',
  preStartTaskDescription: (readOnly: boolean) =>
    `${
      readOnly ? 'Tasks' : 'Set the tasks'
    } that must be completed for the Permit to start`,
  preStartTaskNoRuleText:
    'There is no rules associated with this Pre-Start Task.',
  preStartTasks: 'Pre-Start Tasks',
  preStartTaskStageChangeWarning:
    'Question set change will remove all rules. Are you sure you want to continue?',
  preStartTaskSubDescription:
    'The produce operates based on the order of the list below.',
  preventDeleteQuestionWithRuleText:
    'This question has rule(s) associated with it. Please remove it from all rules before you proceed.',
  preview: 'Preview',
  previewCardsOnlyShowFirstFiveProperties: (n: number) =>
    `Preview cards only show the first ${n} properties.`,
  previouslyAdded: 'Previously Added',
  prioritySetsTheDueDate: 'Priority sets the Due Date',
  proceed: 'Proceed',
  proceedCreateZonesNow:
    'Proceed to begin, or exit to return to Locations Hierarchy.',
  proceedWithoutAssignAlert: 'You must assign someone before proceeding',
  processing: 'Processing',
  profile: 'Profile',
  profileDetails: 'Profile Details',
  profileSaved: 'Profile saved',
  project: 'Project',
  projectAdded: 'Project Added',
  projectAdditions: 'Project Additions',
  projectAdmin: 'Project Admin',
  projectAdminInfo:
    'Project Admins are superusers that can manage all project settings',
  projectAdminTooltip:
    'Has full access to both web and mobile app including ability to issue, reject, extend and close permits. Project Admins can invite new users to SiteAssist and edit their roles.',
  projectCode: 'Project Code',
  projectCreated: 'Project Created',
  projectDocuments: 'Project Documents',
  projectLocation: 'Project Location',
  projectName: 'Project Name',
  projectOverview: 'Project Overview',
  projectPermissions: 'Project Permissions',
  projectRemoved: 'Project Removed',
  projects: 'Projects',
  projectSettings: 'Project Settings',
  projectSlashLocation: 'Project/Location',
  projectsSelected: 'Projects Selected',
  projectStatus: 'Project Status',
  projectsWithProjectAdminRoleOrAuthPersonRole:
    'You are not a Project Admin or Permit Approver in any projects',
  projectTemplates: 'Project Templates',
  projectTemplatesHeaderMessage:
    'Click on a Permit Template to make project-specific configurations. Organisation level configurations must be updated by an Account Owner or SiteAssist Support.',
  projectTimeline: 'Project Timeline',
  projectUpdated: 'Project Updated',
  projectUpdates: 'Project Updates',
  projectUsers: 'Project Users',
  projectUserTooltip:
    'Can request a Permit to Work for themselves but also request and submit a permit to work covering multiple Users.',
  projectViewer: 'Project Viewer',
  projectViewerInfo:
    'Project Viewers can view all project data but cannot make any changes or create any permits or checklists',
  projectViewerTooltip:
    'Can see all the permits in the project but cannot create or approve permits.',
  proofOptional: 'Proof optional',
  proofPhoto: 'Proof Photo',
  proofRequired: 'Proof Required',
  proofs: 'Proofs',
  proofUploadFailed: 'One or more proofs failed to upload',
  properties: 'Properties',
  property: 'Property',
  propertyChange: 'Property Change',
  propertyComponents: 'Property Components',
  propertyDeletedSuccessfully: 'Property deleted successfully',
  propertyName: 'Property Name',
  propertyUpdatedSuccessfully: 'Property updated successfully',
  publish: 'Publish',
  published: 'Published',
  publishedDescription:
    'Making this template live will allow this template to be used in permit requests.',
  publishedPermitTemplate: 'Published Permit Template',
  publishedPlan: 'Published Plan',
  publishPlan: 'Publish Plan',
  publishPlanDescriptionModal:
    'Once published, the zones on this plan will not be editable.',
  publishPlanDescriptionModal2:
    'Please ensure there are no overlapping zones before publishing.',
  QRCode: 'QR Code',
  question: 'Question',
  questionAdditionalInformation: (optional: boolean) =>
    `Additional Information ${optional ? '(Optional)' : ''}`,
  questionBuilder: 'Question Builder',
  questionBuilderDescription:
    'Use the Question Builder to create questions that users have to complete at each stage.',
  questionComponents: 'Question Components',
  questionComponentsReadOnlyDescription:
    'This template version is active. Create a new version to make changes.',
  questionGroup: 'Question Group',
  questions: 'Questions',
  questionSet: 'Question Set',
  questionSpecificWarnings: 'Question Specific Warnings',
  questionTitle: 'Question Title',
  questionType: 'Question Type',
  quickFilters: 'Quick Filters',
  raiseChecklist: 'Raise Checklist',
  raisePermit: 'Raise Permit',
  range: (min = 1, max = 1) => `Range: Max ${max} and Min ${min}`,
  reactivateUser: 'Reactivate User',
  reactivateUserMessage:
    'This will reactivate the user within this project ONLY.',
  read: 'Read',
  readAndUnread: 'Read and unread',
  readMore: 'Read More',
  reason: 'Reason',
  reasonForCancelDots: 'Reason for Cancel...',
  reasonForCancellation: 'Reason for cancellation',
  reasonForExtension: 'Reason for Extension',
  reasonForExtensionRequest: 'Reason for Extension Request',
  reasonForRejection: 'Reason for Rejection',
  reasonForRejectionDots: 'Reason For Rejection',
  reasonForResume: 'Reason for Resume',
  reasonForTransfer: 'Reason for Transfer',
  reasonForTransferDots: 'Reason for Transfer...',
  reasonForUpdate: 'Reason for Update',
  reasonForUpdateDescription:
    'You can leave a note to describe the changes you made',
  recalculateFormulas: 'Recalculate Formulas',
  receivePermit: 'Receive Permit',
  receiver: 'Receiver',
  recent: 'Recent',
  recipient: 'Recipient',
  rectangle: 'Rectangle',
  recurringCheck: 'RECURRING CHECK',
  register: 'Register',
  registerCreated: 'Register Created',
  registerItem: 'Register Item',
  registerItemArchived: 'Register Item Archived',
  registerItemAuthGroupDescription:
    'Select the authorisation group for the user select property',
  registerItemCreated: 'Register Item Created',
  registerItemCreatedNotifications: 'Register item created',
  registerItemDetails: 'Register Item Details',
  registerItemPermissionsDescription:
    'Select which authorisation groups are allowed to add or update items',
  registerItemUnarchived: 'Register Item Unarchived',
  registerItemUpdated: 'Register Item Updated',
  registerItemUpdatedNotifications: 'Register item updated',
  registerName: 'Register Name',
  registerPermissions: 'Register Permissions',
  registerProperties: 'Register Properties',
  registerProperty: 'Register Property',
  registers: 'Registers',
  registerTemplate: 'Register Template',
  registerTemplates: 'Register Templates',
  registerUpdated: 'Register Updated',
  registerUploadTimeout: 'Register upload timeout',
  reject: 'Reject',
  rejectDescription: 'Reject the Permit Request',
  rejected: 'Rejected',
  rejectedBy: 'Rejected By',
  rejectedExtension: 'Extension Rejected',
  rejectExtension: 'Reject Extension',
  rejectionDetails: 'Rejection Details',
  rejectionReason: 'Rejection reason',
  rejectionReasonDescription:
    'Please provide the reason that this request has been rejected.',
  rejectPermit: 'Reject Permit',
  rejectPermitExtension: 'Reject Permit Extension',
  rejectResumeDeclaration:
    'If you want to reject the extension please provide a reason.',
  rejectResumePermitDeclaration:
    'By signing this you acknowledge your responsibility as the current Permit Holder and agree to suspend the permit.',
  rejectTransfer: 'Reject Transfer',
  reload: 'Reload',
  remaining: 'Remaining',
  remove: 'Remove',
  removedChecklistTemplateFromProject:
    'Checklist Template Removed from Project',
  removedPermitTemplate: 'Permit Template Removed from Project',
  removedUserFromAuthGroup: 'Removed User from Auth Group',
  removedUserFromProject: 'Removed User from Project',
  removeFile: 'Remove File',
  removeFilter: 'Remove Filter',
  removeFromProject: 'Remove from Project',
  removeGroupPermissionsMessage:
    'Removing all permissions will delete the group from the list. Are you sure you want to continue?',
  removeParticipant: 'Remove Participant',
  removePermission: 'Remove Permission',
  removeRegisterItemFormulaOverrideWarning:
    'This will remove the override and allow the formula to be calculated according to configuration.',
  removeTransferUser: 'Remove Transfer User',
  rename: 'Rename',
  renameFile: 'Rename File',
  renameFolder: 'Rename Folder',
  renameLevel: 'Rename Level',
  renameLocation: 'Rename Location',
  reportAsIncorrect: 'Report as incorrect',
  reportDownloadComplete: 'Report Download Complete',
  reportDownloadFailed: 'Report Download Failed',
  reports: 'Reports',
  reportType: 'Report Type',
  request: 'Request',
  requestAPermitTitle: 'Request a Permit',
  requestChanges: 'Request Changes',
  requestChangesDescription:
    'Please provide details as to why the permit request cannot be granted at this time',
  requested: 'Requested',
  requestedBy: 'Requested By',
  requestedChange: 'requested a change to this permit request.',
  requestedExtension: 'Extension Requested',
  requesterConfirmationText:
    'By signing this I certify that I have inspected the area in which the works were carried out, that all tools and materials have been removed and that no risk exists from the works specified in this permit and the permit is now closed.',
  requestExtension: 'Request Extension',
  requestExtensionDescription: 'Request an extension for the permit duration',
  requestExtensions: 'Request Extensions',
  requestMessage: 'Request Message',
  requestPermit: 'Request Permit',
  requestPermitAgain: 'Request Permit Again',
  requestSummary: 'Request Summary',
  requestToResumePermit: 'Request to resume permit',
  requestToResumePermitRejected: 'Request to resume permit rejected',
  requireAnnotations: 'Require annotations',
  requireApproval: 'Require approval',
  requireBriefingVerification: 'Do briefings require photo verification?',
  required: 'Required',
  requireGeolocationForBriefings: 'Do briefings require geolocation?',
  requireGeolocationForPermit: 'Do permits require geolocation?',
  requireGeolocationForPostPermitChecks:
    'Do post permit checks require geolocation?',
  requireProofFromCamera: 'Require proof to be taken with camera',
  requireProofFromCameraDescription:
    'Specifies whether a proof has to be a picture taken with the camera or is allowed to be from the gallery.',
  requireProofFromCameraNote:
    'Requires proof from the camera, please save a draft and upload the proof on mobile.',
  requiresAdditionalInformation: 'Requires Additional Information',
  requiresAdditionalInput: 'Requires Input',
  requiresDocument: 'Document Required',
  requiresFinalSignOffQuestion:
    'Does the Permit require Final Signoff to be Closed?',
  requiresProof: 'Requires Proof',
  requiresSignature: 'Requires signature',
  requiresTransferApproval: 'Does Permit Require Transfer Approval',
  requireSubmissionText: 'Require submission text',
  resendEmail: 'Re-send Email',
  resendInvite: 'Resend invite',
  reset: 'Reset',
  resetPassword: 'Reset Password',
  resetPasswordCheckInbox: 'Check your inbox to finish resetting your password',
  respond: 'Respond',
  respondToChanges: 'Respond to Changes',
  respondToChangesDescription:
    'These are the changes that have been requested.',
  restart: 'Restart',
  restore: 'Restore',
  restrictToProjects: 'Restrict to Projects',
  resumeAfterSuspension: 'Resume After Suspension',
  resumeAfterSuspensionTitle: 'Who can resume after suspension?',
  resumed: 'Resumed',
  resumePermit: 'Resume Permit',
  resumePermitDeclaration:
    'By signing this you acknowledge that the permit will be resumed, and activities can recommence in the work area.',
  resumePermitDescription: 'Resume this Permit',
  resumePermitReason: 'Reason for resuming',
  resumePermitRequest: 'Resume Permit Request',
  resumePermitRequested: 'Resume Permit Requested',
  resumeRequestApproved: 'Resume Request Approved',
  resumeRequested: 'Resume Requested',
  resumeRequestReason: 'Resume Request Reason',
  resumeRequestRejected: 'Resume Request Rejected',
  retry: 'Try Again',
  retrying: 'Loading',
  retryOnError: 'Error - tap to retry',
  returnToMainLinkedProcess: 'Return to Main Document',
  review: 'Review',
  reviewAnswer: 'Review Answer',
  reviewExtension: 'Review Extension',
  reviewIssuedAnswers: 'Review Issued Answers',
  reviewPermitInfo: 'Please review the information in the Permit.',
  reviewPermitIssue:
    'Please review the information provided by the issuer of the permit.',
  reviewPermitTransfer: 'Review Permit Transfer',
  reviewQuestionForChecklist: 'Review the questions for the checklist',
  reviewQuestionForPermit: 'Review the questions for the permit',
  reviewResumeRequest: 'Review Resume Request',
  reviewResumeRequestDeclaration:
    'By approving this request, you acknowledge that the permit will be resumed, and all work activities may recommence. By rejecting this request, you acknowledge that the permit will remain suspended, and work activities must not recommence.',
  reviewSubmission: 'Review Submission',
  role: 'Role',
  roleAccessDescriptions: {
    ADD_PARTICIPANT: 'Add permit participants',
    PERIODIC_CHECK_SUBMIT: 'Submit periodic checks',
    VIEW_ONLY: 'View permit access',
  },
  roleAccountOwnerTooltip:
    'Have access to all Organisation and Project settings. Can create templates to be used by projects as well as editing and viewing all projects.',
  roleAccountViewerTooltip:
    'Can see all data in an organisation. Actions they can perform are controlled by project membership',
  roleDescription: 'Role Description',
  roleName: 'Role Name',
  rolePermissions: 'Role Permissions',
  roles: 'Roles',
  roleUserTooltip:
    'A User is invited to your account and can have multiple roles depending on permission level on a project level. Can be Project Admin, Permit Approver, User, or Project Viewer in projects.',
  rootRole: 'Root Role',
  rotateScreen: 'Rotate Screen',
  row: 'Row',
  save: 'Save',
  saveAnnotations: 'Save Annotations',
  saveAreas: (featureCount: number) => `Save ${featureCount} Area(s)`,
  saveCheck: 'Save Check',
  saveDraft: 'Save Draft',
  saveDraftDescription:
    'Write a note so that you can find the draft easily later',
  saveLocations: 'Save Locations',
  saveNewDraft: 'Save New Draft',
  saveNow: 'Yes, save now',
  saveProjectPlanAreasDescription:
    'Would you like to save your zones before you close/exit?',
  saveRole: 'Save Role',
  saveSelectedProjectPlanAreaDescription:
    'Save to continue raising this permit, or cancel to edit this selection.',
  saveTemplate: 'Save Template',
  scanQRCode: 'Scan QR Code',
  scanQRCodeDescription: 'Scan a SiteAssist QR code for quick actions',
  scanQRCodeToRecordAttendance: 'Scan QR Code to Record Attendance',
  scanQRCodeToRecordAttendanceDescription:
    'Please use your device’s camera to scan the QR code to record briefing attendance.',
  seachByNameOrEmail: 'Search by name or email',
  search: 'Search',
  searchByNameOrEmail: 'Search by Name or Email',
  searchByNameOrId: 'Search by Name or ID',
  searchByRegisterName: 'Search by register name',
  searchPermits: 'Search Permits',
  secondsShort: 's',
  seeAllChecks: 'See All Checks',
  seeDetails: 'See Details',
  seeMore: 'See more',
  select: 'Select',
  selectAction: 'Select Action',
  selectAll: 'Select All',
  selectAnExistingDocumentOrUpload:
    'Select an existing document or upload a new one',
  SelectAssignee: 'Select assignee',
  selectAuthGroup: 'Select Authorisation Group',
  selectBetween: 'Please select between {min} and {max} options',
  selectBriefingParticipants: 'Select Participants to Brief',
  selectChecklistsToLink: 'Select Checklists to Link',
  selectChecklistToView: 'Select a checklist to preview it',
  selectChecklistType: 'Select Checklist Type',
  selectCsvImport: 'Select a CSV file to import or drag and drop it here',
  selectDateAndTime: 'Select Date & Time',
  selectDates: 'Select Dates',
  selectDestinationOrganisation: 'Select destination organisation',
  selectDocument: 'Select Document',
  selected: (selectedNumber: number) => `${selectedNumber} Selected`,
  selectedZones: 'Selected Zones',
  selectEndDate: 'Select End Date',
  selectFilter: 'Select Filter',
  selectFilterCondition: 'Select Filter Condition',
  selectInspection: 'Select Inspection',
  selectLocation: 'Select Location',
  selectOptions: (minOptions) => `Please select ${minOptions} option(s)`,
  selectOrTypeInTemplate: 'Select or Type in Template',
  selectParticipants: 'Select Participants',
  selectPermit: 'Select Permit',
  selectPermitDateRange: 'Select Permit Date Range',
  selectPermitStatus: 'Select Permit Status',
  selectPermitsToLink: 'Select Permits to Link',
  selectPermitToView: 'Select a permit to preview it',
  selectPermitType: 'Select Permit Type',
  selectPermitTypes: 'Select Permit Types',
  selectProject: 'Select Project',
  selectProjects: 'Select Projects',
  selectQuestionPlaceholderText: 'Select Question / Group',
  selectQuestionType: 'Select Question Type',
  selectRegisterItem: 'Select Register Item',
  selectRole: 'Select Role',
  selectStartDate: 'Select Start Date',
  selectStatus: 'Select Status',
  selectSubZone: 'Select Sub Zone',
  selectTemplate: 'Select Template',
  selectTransferUser: 'Select Transfer User',
  selectType: 'Select Type',
  selectUser: 'Select User',
  selectView: 'Select View',
  selectZone: 'Select Zone',
  selectZones: 'Select Zones',
  send: 'Send',
  sendEmail: 'Send Email',
  sendForgotPasswordLink: 'Send Forgot Password Link',
  sending: 'Sending',
  sendRequest: 'Send Request',
  sentToPermitHolder: 'Sent to Permit Holder',
  setAnswers: (answers: string[]) =>
    `Set ${answers?.length || ''} answer${answers?.length > 1 ? 's' : ''}`,
  setConditions: 'Set Conditions',
  setExpiry: 'Set Expiry',
  setLocation: 'Set Location',
  setManually: 'Set Manually',
  setTimezone: 'Set Timezone',
  settings: 'Settings',
  shortened: 'Shortened',
  shortenInformation: 'Shorten Information',
  shortenPermit: 'Shorten Permit',
  shortenPermitDescription: 'Reduce the duration of this permit',
  showAll: 'Show All',
  showAllComments: 'Show All Comments',
  showArchived: 'Show Archived',
  showChecklists: 'Show Checklists',
  showGroupIfAnswer: 'Show Group if answer is:',
  showIfAnswerIs: 'Show if answer is',
  showInfo: 'Show Info',
  showLocation: 'Show Location',
  showMore: 'Show More',
  showOnMap: 'Show on map',
  showPermits: 'Show Permits',
  showPhotos: 'Show Photos',
  showQRCode: 'Show QR Code',
  showSignature: 'Show Signature',
  showZones: 'Show Zones',
  sign: 'Sign',
  signAndReject: 'Sign and Reject',
  signAndSubmit: 'Sign and Submit',
  signature: 'Signature',
  signatureOptional: 'Signature Optional',
  signatureRequired: 'Signature Required',
  signed: 'Signed',
  signedBy: 'Signed by',
  signedOffBy: 'Signed Off By',
  signedOn: 'Signed on',
  signedVia: 'Signed via',
  signing: 'Signing',
  signSpotInstruction: 'Sign within the dotted lines',
  singleSignOnUserAlert:
    'This user is configured for Single-Sign using your corporate system. Please speak to your IT support team about resetting you password',
  siteBox: 'SiteBox',
  siteboxDocument: 'SiteBox Document',
  siteBoxDocumentCreated: 'SiteBox Document Created',
  siteBoxDocumentMoved: 'SiteBox Document Moved',
  siteBoxDocumentRenamed: 'SiteBox Document Renamed',
  siteBoxDocumentStatusChanged: 'SiteBox Document Status Changed',
  siteBoxDocumentUpdated: 'SiteBox Document Updated',
  siteBoxDocumentVersion: 'SiteBox Document Version',
  siteBoxDocumentVersionCreated: 'SiteBox Document Version Created',
  siteBoxDocumentVersionStatusChanged:
    'SiteBox Document Version Status Changed',
  siteLocation: 'Site Location',
  siteLocations: 'Site Locations',
  skip: 'Skip',
  skipPostPermitChecksQuestion:
    'Does the Permit Holder need to do Post Permit Checks?',
  sortBy: 'Sort By',
  source: 'Source',
  sourceOrganisation: 'Source Organisation',
  ssoLoginNotAvailable:
    'SSO login is not available. Please log in with another user',
  ssoRedirectMessage: `You will now be directed to your company's login page. Please use your company credentials to log in.`,
  stage: 'Stage',
  start: 'Start',
  startDate: 'Start Date',
  startDateAndTime: 'Start date & time',
  startDateMustBeInTheFuture: 'Start date must be in the future',
  started: 'Started',
  startHere: 'Start Here',
  starts: 'Starts',
  startsOnAscending: 'Starts (oldest first)',
  startsOnDescending: 'Starts (newest first)',
  startTime: 'Start Time',
  status: 'Status',
  statusesSelected: 'Statuses Selected',
  statusTrail: 'Status Trail',
  stepDescription: 'Step Description',
  stepRole: 'Step Role',
  steps: 'Steps',
  stepTitle: 'Step Title',
  stepType: 'Step Type',
  submit: 'Submit',
  submitChecklist: 'Submit Checklist',
  submitPeriodicCheck: 'Submit Periodic Check',
  submitPeriodicChecks: 'Submit Periodic Checks',
  submitPostPermitChecks: 'Submit Post Permit Checks',
  submitRelevantChecksForPermit: 'Submit relevant checks for this permit',
  submitSignature: 'Submit Signature',
  submitted: 'Submitted',
  submittedBy: 'Submitted By',
  succesfullyMovedFile: 'Successfully moved file',
  succesfullyMovedTemplate: 'Successfully moved template',
  successfullyDeleted: 'Successfully Deleted',
  successfullyImportedRows: (rowNumber: number) =>
    `Successfully imported ${rowNumber} row${rowNumber > 1 ? 's' : ''}`,
  successfullyUploadedItems: 'Successfully uploaded Items',
  summary: 'Summary',
  superAdmin: 'Super Admin',
  supervisor: 'Supervisor',
  supervisorTooltip:
    'Can request an individual permit to work for themselves but also request and submit a permit to work covering multiple Operatives. Can view all permits related to them.',
  support: 'Support',
  supportInfo: "Message us and we'll be right with you",
  supportUser: 'Support User',
  suspended: 'Suspended',
  suspendedBy: 'Suspended By',
  suspendPermit: 'Suspend Permit',
  suspendPermitDeclaration:
    'By signing this you acknowledge that this permit will be suspended, and all activities in the work area must be stopped until the permit is resumed.',
  suspendPermitDescription: 'Temporarily suspend this permit',
  suspendPermitReason: 'Reason for suspension',
  suspensionReason: 'Suspension Reason',
  switchToDiagramView: 'Switch to Diagram View',
  switchUser: 'Switch User',
  syncAll: 'Sync All',
  synced: 'Synced',
  syncedAt: 'Synced at',
  syncedFromSyncTray: 'Synced from sync Tray',
  syncNow: 'Sync Now',
  table: 'Table',
  tableOfContents: 'Table of Contents',
  tagArchived: 'Tag archived',
  tagCreated: 'Tag created',
  tags: 'Tags',
  tagUpdated: 'Tag updated',
  takePhotoAction: 'take a photo',
  tapOnAFileForSelection: 'Tap on a file for selection',
  tapToCaptureSignature: 'Tap to capture signature',
  tapToEditSignature: 'Tap to edit signature',
  taskCreatedSuccessfully: 'Task created successfully',
  taskDeletedSuccessfully: 'Task deleted successfully',
  taskMovedSuccessfully: 'Task moved successfully',
  taskName: 'Task Name',
  tasks: 'Tasks',
  taskTemplates: 'Task Templates',
  taskUpdatedSuccessfully: 'Task updated successfully',
  team: 'Team',
  teamsAndRoles: 'Team and Roles',
  telephoneNumber: 'Telephone number',
  template: 'Template',
  templateAddedToProject: 'Template added to Project',
  templateArchived: 'Template Archived',
  templateBuilder: 'Template Builder',
  templateChecklistRoles: 'Checklist Roles',
  templateCreated: 'Template created',
  templateDescription: 'Template Description',
  templateName: 'Template Name',
  templateNotInProject: 'Template not active in project',
  templateOnlyViewWarningDescription:
    'If you think there should be changes made to this template please speak to:',
  templateOnlyViewWarningDescriptionOwner:
    'Please go to the organisation level to edit it: ',
  templateOnlyViewWarningTitle:
    'Templates can only be edited at the Organisation level by an Account Owner',
  templateOnlyViewWarningTitleOwner:
    'Templates can only be edited at the Organisation level',
  templatePermitRoles: 'Permit Roles',
  templatePublished: 'Template Published',
  templateQuestionsText: (
    noPermitHolderQuestions: boolean,
    noAuthPersonQuestions: boolean,
  ) => {
    return localizedStrings.formatString(
      localizedStrings.emptyQuestionsMessage,
      `${noPermitHolderQuestions ? localizedStrings.permitHolder : ''} ${
        noPermitHolderQuestions && noPermitHolderQuestions ? ' and ' : ''
      } ${noAuthPersonQuestions ? localizedStrings.authorisedPerson : ''}`,
      `${noPermitHolderQuestions && noAuthPersonQuestions ? 's' : ''}`,
    ) as string
  },
  templateRemovedFromProject: 'Template removed from Project',
  templates: 'Templates',
  templateSettings: 'Template Settings',
  templatesUpdated: 'Templates Updated',
  templateTitle: 'Template Title',
  templateUnarchived: 'Template Unarchived',
  templateUniqueCode: 'Template Unique Code',
  templateUnpublished: 'Template Unpublished',
  templateUpdated: 'Template updated',
  templateVersion: 'Template Version',
  templateVersionUpdated: 'Template Version Updated',
  test: 'Test',
  testOrganisation: 'Test Organisation',
  testOrganisations: 'Test Organisations',
  text: 'Text',
  textSection: 'Text Section',
  theFollowingPropertiesWillBeCalculated:
    'The following properties will be calculated',
  thenShow: 'Then show',
  thePermitWasCancelled:
    'The Permit Request was cancelled. Please see below for more information.',
  thePermitWasRejected:
    'The Permit Request was rejected. Please see below for more information.',
  theSelectedExpiryTimeCannonExceedXDaysYHours: (expiryDateTime) =>
    `The selected expiry time cannot exceed ${expiryDateTime}`,
  thisGroupHasNoMembers: 'This group has no members',
  thisGroupHasNoPermits: 'This group has no permits',
  thisInspectionHasAMaximumExpiryOfXDaysYHours: (expiryDateTime) =>
    `This inspection has a maximum expiry of ${expiryDateTime}`,
  thisOrganisationHasNoApiKeys: 'This organisation has no API keys',
  thisPermitWillBeAppliedToSelectedZone:
    'This permit will be applied to the zone you have selected',
  thisProjectHasNoGroups: 'This project has no groups',
  thisWillDeleteTheFollowingRole: 'This will delete the following role',
  thisWillRemoveParticipant: 'This will remove the participant.',
  time: 'Time',
  timeline: 'Timeline',
  timeTravelerMessage:
    "It looks like your device's clock is set to the past. Unfortunately, our app hasn't invented time travel compatibility yet.\n\nPlease reset your date to the present to continue.",
  timeUntilPermitExpires: 'Time until permit expires',
  timezone: 'Timezone',
  title: 'Title',
  to: 'to',
  toastTesting: 'Toast testing',
  toDo: 'To-do',
  toEditTheAnnotationsOfThisItemSwitchToDiagramView:
    'To edit the annotations of this item, switch to the Diagram View.',
  toggleProjectLocationInfo:
    'Turning on project locations will enable the below Location/Area heirarchy in the mobile app.  Turning this off will allow for free text locations in mobile app actions and the Location/Area heirarchy below will be ignored.',
  tooltipExpired:
    'Expired permits. Permit Holder must declare that work is complete or be Revoked by an authorised user.',
  tooltipExtensionRequested: 'Permits that have an extension request',
  tooltipFinalSignoff:
    'Works and potential post permit checks are completed. An authorised user must and review and sign off to close the permit.',
  tooltipIssued: 'Permits that are currently in a Pre-Start state',
  tooltipOpen: 'Permits that are currently Open',
  tooltipPostPermitChecks:
    'Work has been declared complete and the Permit Holder must complete checks or wait for a countdown (fire watch for example)',
  tooltipRequested:
    'Permits requested on Site. Must be reviewed by an authorised user.',
  totalChecklists: 'Total Checklists (all time)',
  totalDrawnAreaSize: 'Total drawn area size:',
  totalPermits: 'Total Permits (all time)',
  transferAccepted: 'Transfer Accepted',
  transferApproved: 'Transfer Approved',
  transferAwaitingApproval: 'Transfer Awaiting Approval',
  transferAwaitingRecipient: 'Transfer Awaiting Recipient',
  transferCanceled: 'Transfer Canceled',
  transferDeclined: 'Transfer Declined',
  transferDetails: 'Transfer Details',
  transferFrom: 'Transfer From',
  transferInformation: 'Transfer Information',
  transferingTo: 'Transfering To',
  transferInitiated: 'Transfer Initiated',
  transferInitiatedBy: 'Transfer Initiated By',
  transferOwnershipOnBriefing: 'Transfer Permit Ownership on Briefing',
  transferPermit: 'Transfer Permit',
  transferPermitDescription:
    'Transfer the ownership of this permit to another SiteAssist user',
  transferPermitDisclaimer:
    'By signing this transfer you acknowledge that the permit will change ownership. You will no longer be the permit holder.',
  transferReason: 'Transfer Reason',
  transferRejected: 'Transfer Rejected',
  transferRequest: 'Transfer Request',
  transferRequested: 'Transfer Requested',
  transferRequestMade:
    'A request has been made to transfer the ownership of this permit to',
  transferSent: 'Transfer Sent',
  transferTo: 'Transfer To',
  trigger: 'Trigger',
  triggers: 'Triggers',
  type: 'Type',
  typeDeleteToConfirm: 'Type DELETE to confirm',
  typeHere: 'Type here...',
  typeOrSelect: 'Type or Select',
  unarchive: 'Unarchive',
  unarchivedChecklistTemplate: 'Checklist Template Unarchived',
  unarchivedPermitTemplate: 'Unarchived Permit Template',
  unauthorisedAction: 'You are not authorised to perform this action.',
  unauthorisedActions: 'You are not authorised to perform these actions.',
  unauthorized: 'UNAUTHORIZED',
  uncaughtError:
    'Sorry, something unexpected happened. The error has been automatically reported to us.',
  unimpersonateUser: 'Unimpersonate User',
  uniqueCode: 'Unique Code',
  uniqueId: 'Unique ID',
  uniqueIdSystemControlled: 'Unique ID System Controlled',
  unknown: 'Unknown',
  unknownAddress: 'Unknown Address',
  unpublish: 'Unpublish',
  unpublished: 'Unpublished',
  unpublishedPlan: 'Unpublished Plan',
  unread: 'Unread',
  unreadOnly: 'Unread only',
  unsavedChanges: 'Unsaved Changes',
  unsigned: 'Unsigned',
  update: 'Update',
  updated: 'Updated',
  updatedAuthGroupPermissions: 'Updated Auth Group Permissions',
  updatedBy: 'Updated by',
  updatedChecklistTemplate: 'Checklist Template Updated',
  updatedChecklistVersion: 'Checklist Version Updated',
  updatedFrom: 'Updated from',
  updatedOrganisationPermissions: 'Updated User Permissions – Organisation',
  updatedOrgAuthGroupPermissions: 'Updated Organisation Auth Group Permissions',
  updatedPermitApprovalSettings: 'Updated Permit Approval Settings',
  updatedPermitTemplate: 'Permit Template Updated',
  updatedPermitTemplateVersion: 'Permit Version Updated',
  updatedProjectChecklistVersionSettings:
    'Project Checklist Version Settings Updated',
  updatedProjectPermissions: 'Updated User Permissions – Project',
  updatedProjectPermitSettings: 'Updated Permit Settings – Project',
  updatedProperties: 'Updated Properties',
  updateDraft: 'Update Draft',
  updateItem: 'Update Item',
  updateItems: 'Update Items',
  updateRegisterItem: 'Update Register Item',
  updateUser: 'Update User',
  upload: 'Upload',
  uploadADiagramToStartCreatingNewItems:
    'Upload a diagram to start creating new items.',
  uploadAPhoto: 'Upload a Photo',
  uploadComplete: 'Upload Complete',
  uploadCsvImportant1: 'Use the provided template to enter asset data.',
  uploadCsvImportant2: "Don't add new columns or edit column headers.",
  uploadCsvStep1: '1. Download the CSV Template',
  uploadCsvStep2: '2. Populate template with asset data',
  uploadCsvStep3: '3. Save your file as a .CSV and upload',
  uploadCsvStepsDescription: 'To upload an CSV file, please follow these steps',
  uploadDocument: 'Upload Document',
  uploaded: 'Uploaded',
  uploadedBy: 'Uploaded by',
  uploadFailed: 'Upload Failed',
  uploadFile: 'Upload File',
  uploadImage: 'Upload Photo',
  uploading: 'Uploading',
  uploadingData: 'Uploading Data',
  uploadLogo: 'Upload Logo',
  uploadNewVersion: 'Upload New Version',
  uploadPdf: 'Upload PDF',
  uploadPlan: 'Upload Plan',
  uploadProof: 'Upload Proof',
  upTo: 'Up to {0}',
  user: 'User',
  userAlreadyVerifiedAlert:
    'You have already set a password for this user, please log in using that password.',
  userCreated: 'User Created',
  userDeleted: 'User Deleted',
  userDetails: 'User Details',
  userDisabled: 'User Disabled',
  userInformation: 'User Information',
  userInvolved: 'User Involved',
  userProperties: 'User Properties',
  userProperty: 'User Property',
  userRole: 'User Role',
  userRoleNotUpdated: 'User role could not be updated',
  userRoleUpdated: 'User role updated',
  users: 'Users',
  userSelect: 'User Select',
  userSettingsOnOtherProjectsUnchanged:
    'Their settings on other projects will be unaffected.',
  userType: 'User Type',
  userUpdated: 'User Updated',
  userUpdateFailed: 'User Update Failed',
  validAnswer: 'Valid Answer',
  validAnswerOption: 'Enter A Valid Answer Option',
  validations: 'Validations',
  value: 'Value',
  valueMustBePositive: 'Value must be positive',
  versionActivated: 'Version activated',
  versionDeleted: 'Version Deleted',
  versionHistory: 'Version History',
  versions: 'Versions',
  view: 'View',
  viewActions: 'View Actions',
  viewAll: 'View All',
  viewAllDocuments: 'View all Documents',
  viewAllPeriodicChecks: 'View All Periodic Checks',
  viewAllRegisters: 'View All Registers',
  viewAndDoPeriodicCheck: 'View all periodic checks and do a new check',
  viewChecklist: 'View Checklist',
  viewConditions: 'View Conditions',
  viewCreated: 'View Created',
  viewDeleted: 'View Deleted',
  viewDetails: 'View Details',
  viewDocument: 'View Document',
  viewDocuments: 'View all {0} Documents',
  viewDocumentToAcknowledge: 'View document to acknowledge',
  viewDraft: 'View Draft',
  viewInformation: 'View Information',
  viewMore: 'View More',
  viewName: 'View Name',
  viewOnMap: 'View on Map',
  viewOnPlan: 'View on Plan',
  viewParticipant: 'View Participant',
  viewPermit: 'View Permit',
  viewPermits: 'View Permits',
  viewPermitTimeline: 'View Permit Timeline',
  viewPlan: 'View Plan',
  viewPlans: 'View Plans',
  viewProfile: 'View Profile',
  viewReason: 'View Reason',
  viewRequestedChanges: 'View Requested Changes',
  views: 'Views',
  viewTask: 'View Task',
  viewTemplateSettings: 'View Template Settings',
  viewUpdated: 'View Updated',
  w3wDoesNotExist: 'This what3words address does not exist',
  w3wNoInternetConnection:
    'To select a what3words location using the map, an internet connection is required. However, you can still manually enter the what3words location without internet access.',
  warningConfig: 'Warning Config',
  warningDetected: 'Warning Detected',
  warningInformation: 'Warning Information',
  warningLostDataMessageConfimation:
    'Changing this will result in loss of warning data for this question. Are you sure you want to continue?',
  warningMessage: 'You must finish create question before adding a warning',
  warningReportedAlertMessage: `Thank you for your feedback. We're reviewing it to improve the system.`,
  warningReportedMessage: 'This warning has been reported',
  warnings: 'Warnings',
  warningSettings: 'Warning Settings',
  warningText: 'Warning Text',
  warningType: 'Warning Type',
  webBrowser: 'Web Browser',
  weHaveSentEmail: "We've sent you an email",
  welcomeBack: (name: string) => `Welcome back${name ? `, ${name}` : ''}!`,
  weSeeYoureOnMobileMessage:
    'The SiteAssist mobile app is a much better experience for mobile users!',
  what3Words: 'What 3 Words',
  workComplete: 'Work Complete',
  workCompleteDescription:
    'Declare work complete so that the permit can be closed off',
  workflow: 'Workflow',
  wouldYouLikeToClose: 'Would you like to Close the Permit?',
  wouldYouLikeToReject:
    'Would you like to Reject or Issue the Permit to Work Request?',
  yearsShort: 'y',
  yes: 'Yes',
  yesPlease: 'Yes please',
  you: 'You',
  youAreOffline: 'You are Offline',
  youAreUpToDate: "You're up to date",
  youCannotPerformThisAction: 'You cannot perform this action',
  youCantPerformThisAction: 'You can not perform this action!',
  youDontHaveAnyCustomViewsYet: `You don't have any custom views yet.`,
  youHaveBeenAddedToAChecklist: 'You have been added to a checklist',
  youHaveBeenAddedToAPermit: 'You have been added to a permit',
  zone: 'Zone',
  zoneLabel: 'Zone Label',
  zones: 'Zones',
  zoneSelected: (selectedZones: number) =>
    `${selectedZones > 0 ? selectedZones : 'No'} ${
      selectedZones > 1 ? 'Zones' : 'Zone'
    } Selected`,
  zoom: 'Zoom',
}

const Strings: Record<SupportedLanguagesType, StringsType> = {
  en: enBaseStrings,
}

export const localizedStrings = new LocalizedStrings(Strings)
