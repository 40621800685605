import { useQuery } from '@tanstack/react-query'
import { projectPlansKeys } from '../projectPlansKeys'

import { RequestTypes } from '../requestTypes'
import { UseQueryOptions } from '@tanstack/react-query/src/types'
import { ProjectPlan } from '@core/entities/project/ProjectPlan/ProjectPlan'
import { ProjectPlanArea } from '@core/entities/project/ProjectPlanArea'

const projectPlanTransform = (data: ProjectPlan) =>
  ProjectPlan.new({ ...data, areas: data.areas.map(ProjectPlanArea.new) })

export const useGetProjectPlan = (
  payload: RequestTypes['useGetProjectPlan'],
  options?: UseQueryOptions<ProjectPlan, string>,
) => {
  const disable = !payload.projectId || !payload.nodeId || !payload.planId

  return useQuery({
    select: projectPlanTransform,
    ...projectPlansKeys.byProjectLocationPlan(payload),
    ...options,
    ...(disable ? { enabled: false } : {}),
  })
}
