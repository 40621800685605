import { useGetProfile } from '@core/react-query/features/profile'
import { ITemplateQuestion } from '@core/entities/template/TemplateQuestion'
import moment from 'moment/moment'

export const useTimeStamp = () => {
  const { data: profile } = useGetProfile({
    enabled: false,
  })

  const addTimeStampToTemplateQuestion =
    (
      onChange: (question: ITemplateQuestion) => void,
      clearSignature?: boolean,
    ) =>
    (question: ITemplateQuestion) => {
      onChange({
        ...question,
        lastEdit: {
          editedOn: moment() as unknown as string,
          userId: profile?.id,
        },
        signatureUrl: clearSignature ? null : question.signatureUrl,
        localSignature: clearSignature ? null : question.localSignature,
      })
    }

  return { addTimeStampToTemplateQuestion }
}
