import { localizedStrings } from '@core/strings'
import React, { forwardRef, useImperativeHandle, useCallback } from 'react'
import { Box, BoxProps, Flex, Image, Text } from '@fundamentals'
import { Button } from '@common/material'
import { ControllerRenderProps } from 'react-hook-form'
import SignatureCanvas from 'react-signature-canvas'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material'

type CaptureSignatureProps = BoxProps & {
  inputProps?: Partial<ControllerRenderProps>
  optional?: boolean
  error?: string
  readOnly?: boolean
}

const StyledSignatureBox = styled(Box)`
  aspect-ratio: 2;
`

const CaptureSignature: React.FC<CaptureSignatureProps> = forwardRef(
  (
    { inputProps: { value, onChange } = {}, optional, readOnly, ...props },
    ref,
  ) => {
    const signatureRef = React.useRef(null)
    const [canSign, setCanSign] = React.useState(!value && !readOnly)
    const [isSigning, setIsSigning] = React.useState(false)
    const theme: any = useTheme()

    const clearSignature = () => {
      !canSign && setCanSign(true)
      signatureRef.current?.clear()
      onChange(null)
    }

    useImperativeHandle(ref, () => ({
      clearSignature,
    }))

    const renderSignatureField = useCallback(() => {
      if (!readOnly && canSign) {
        const isEmpty = signatureRef.current?.isEmpty() ?? true

        return (
          <Box {...props}>
            <StyledSignatureBox height='100%' maxHeight={200}>
              <Box
                position='relative'
                overflow='hidden'
                border='1px solid lightgrey'
                borderRadius={1}
                boxSizing='border-box'
                height='100%'
                data-test='signature-input'
                sx={{ '.signature_canvas': { height: '100%', width: '100%' } }}
              >
                <SignatureCanvas
                  ref={signatureRef}
                  penColor={theme.palette.primary.main}
                  onBegin={() => setIsSigning(true)}
                  onEnd={() => {
                    signatureRef.current
                      ?.getCanvas()
                      .toBlob((signatureBlob) => {
                        const file = new File(
                          [signatureBlob],
                          'signature.png',
                          {
                            type: 'image/png',
                          },
                        )
                        onChange(file)
                        setIsSigning(false)
                      })
                  }}
                  canvasProps={{ className: 'signature_canvas' }}
                />
                {!value && !isSigning && isEmpty && (
                  <Flex
                    position='absolute'
                    left={0}
                    right={0}
                    top={0}
                    bottom={0}
                    alignItems='center'
                    justifyContent='center'
                    sx={{ 'pointer-events': 'none' }}
                  >
                    <Text fontSize='14px' color='grey'>
                      {` Sign in this space${optional ? ' (Optional)' : ''}`}
                    </Text>
                  </Flex>
                )}
              </Box>
              <Button
                variant='text'
                onClick={clearSignature}
                sx={{ float: 'right', mt: 0.5, mb: -1.5 }}
              >
                {localizedStrings.clear}
              </Button>
            </StyledSignatureBox>
          </Box>
        )
      }

      return (
        <Box {...props}>
          <StyledSignatureBox maxHeight={200} data-test='signature-input'>
            <Image
              src={value}
              alt={localizedStrings.signature}
              sx={{ height: '100%' }}
              data-test='signature-img'
            />
          </StyledSignatureBox>
          {!readOnly && (
            <Button
              variant='text'
              onClick={() => setCanSign(true)}
              sx={{ float: 'right', mt: 0.5, mb: -1.5 }}
            >
              {localizedStrings.edit}
            </Button>
          )}
        </Box>
      )
    }, [readOnly, canSign, isSigning, signatureRef.current?.isEmpty()])

    return (
      <Box my={2} data-test={props.dataTest}>
        <Text color='grey.600' fontSize='14px' mb={1}>
          {localizedStrings.signature}
        </Text>
        {renderSignatureField()}
      </Box>
    )
  },
)

export default CaptureSignature
