import { localizedStrings } from '@core/strings'
import React, { useContext, useState } from 'react'
import { IconButton, MenuItem, Tooltip } from '@common/material'
import { Box, Flex, Text } from '@fundamentals'
import { CheckBox, MenuOpenOutlined } from '@mui/icons-material'
import { alpha } from '@mui/material'
import { TableSecondaryText } from '@common/Table'
import { ProjectPlanPermits } from '@core/entities/project/ProjectPlanPermits'
import {
  PROJECT_PLAN_AREA_COLORS,
  ProjectPlanPSPDFArea,
} from '@core/entities/project'
import {
  ProjectPlanStateContext,
  selectInstance,
  selectPSPDFKit,
  selectProjectPlanPSPDFAreas,
  selectProjectPlanPSPDFSelectedAreas,
} from 'webapp/state-machines/projectPlan'
import { useSelector } from '@xstate/react'

type ProjectPlanZonesSidebarLegacyProps = {
  planPermits: ProjectPlanPermits
  initialSelectedAreas: string[]
  handleZoneMouseOver: (event, annotationId: string) => void
  handleZoneMouseOut: () => void
  handleSidebarExpand: (isSidebarExpanded: boolean) => void
}

export const ProjectPlanZonesSidebarLegacy: React.FC<
  ProjectPlanZonesSidebarLegacyProps
> = ({
  planPermits,
  initialSelectedAreas,
  handleZoneMouseOver,
  handleZoneMouseOut,
  handleSidebarExpand,
}) => {
  const projectPlanStateContext = useContext(ProjectPlanStateContext)

  const instance = useSelector(
    projectPlanStateContext.projectPlanService,
    selectInstance,
  )

  const PSPDFKit = useSelector(
    projectPlanStateContext.projectPlanService,
    selectPSPDFKit,
  )

  const projectPlanPSPDFAreas: ProjectPlanPSPDFArea[] = useSelector(
    projectPlanStateContext.projectPlanService,
    selectProjectPlanPSPDFAreas,
  )

  const projectPlanPSPDFSelectedAreas = useSelector(
    projectPlanStateContext.projectPlanService,
    selectProjectPlanPSPDFSelectedAreas,
  )

  const projectPlanPSPDFSelectedAreaIds = projectPlanPSPDFSelectedAreas.map(
    (area) => area.id,
  )
  const [expanded, setExpanded] = useState(true)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const selectedZoneNames = planPermits?.permitStats?.getSelectedAreaNames(
    projectPlanPSPDFSelectedAreas.length || instance
      ? projectPlanPSPDFSelectedAreas.map((area) => area.id)
      : initialSelectedAreas,
  )

  return (
    <Box
      zIndex={1}
      sx={{
        minWidth: '4rem',
        maxWidth: '18.5rem',
        width: expanded ? '100%' : '4rem',
        transition: 'width 0.2s ease-in-out',
        boxShadow: `0px 3px 5px -1px ${alpha('#000', 0.2)}`,
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      <Flex
        pl={1.4}
        position='sticky'
        top={0}
        sx={{ background: 'white' }}
        zIndex={1}
        flexDirection='column'
      >
        <Flex alignItems='center'>
          <IconButton
            onClick={() => {
              handleExpandClick()
              handleSidebarExpand(!expanded)
            }}
            sx={{ borderRadius: 0 }}
          >
            <MenuOpenOutlined
              sx={{
                transition: 'transform 0.2s ease-out',
                transform: expanded ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            />
          </IconButton>

          <Text noWrap sx={{ opacity: expanded ? 1 : 0 }}>
            {`[${planPermits?.permitStats?.countByArea?.length ?? 0}] ${
              localizedStrings.zones
            }`}
          </Text>
        </Flex>

        {expanded && (
          <Tooltip
            title={selectedZoneNames?.length ? selectedZoneNames : ''}
            placement='right'
            arrow
          >
            <Flex
              px={2.6}
              ml={-1.4}
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.08)',
                },
              }}
              alignItems={
                selectedZoneNames?.length > 0 ? 'flex-start' : 'center'
              }
            >
              <Text
                opacity={expanded ? 1 : 0}
                fontSize={15}
                color='primary.main'
              >
                {`[${
                  projectPlanPSPDFSelectedAreaIds.length || instance
                    ? projectPlanPSPDFSelectedAreaIds.length
                    : initialSelectedAreas.length
                }]`}
              </Text>
              <Box pl={1.6} overflow='hidden'>
                <Text
                  noWrap
                  opacity={expanded ? 1 : 0}
                  fontSize={14}
                  color='primary.main'
                >
                  {localizedStrings.selectedZones}
                </Text>

                <Text
                  noWrap
                  opacity={expanded ? 1 : 0}
                  color='text.primary'
                  fontSize={12}
                >
                  {selectedZoneNames}
                </Text>
              </Box>
            </Flex>
          </Tooltip>
        )}
      </Flex>

      {(planPermits?.permitStats?.countByArea?.length > 1
        ? [
            {
              areaUuid: 'all',
              areaColor: '#000',
              areaName: localizedStrings.selectAll,
              totalElements: planPermits?.permitStats?.totalElements,
            },
            ...planPermits.permitStats.countByArea,
          ]
        : planPermits?.permitStats?.countByArea
      )?.map((area) => {
        const color = PROJECT_PLAN_AREA_COLORS.find(
          (color) => color.enum === area.areaColor,
        )
        const annotation =
          PSPDFKit &&
          projectPlanPSPDFAreas.length > 0 &&
          area.areaUuid !== 'all'
            ? projectPlanPSPDFAreas.find(
                (annotation) => annotation.id === area.areaUuid,
              )
            : {
                id: area.areaUuid,
                strokeColor: color?.hex || area.areaColor,
                fillColor: color?.hex || area.areaColor,
                opacity: 0.3,
                name: area.areaName,
              }

        const isSelectAll = annotation.id === 'all'
        return (
          <MenuItem
            key={annotation.id}
            sx={{
              width: expanded ? '100%' : 'fit-content',
            }}
            selected={projectPlanPSPDFSelectedAreaIds.includes(annotation.id)}
            disabled={!PSPDFKit}
            onMouseOver={() => {
              handleZoneMouseOver(null, annotation.id)
            }}
            onMouseLeave={() => handleZoneMouseOut()}
            onClick={async () => {
              if (isSelectAll) {
                const areAllSelected =
                  projectPlanPSPDFAreas.filter((ann) => ann.opacity > 0.4)
                    .length === projectPlanPSPDFAreas.length

                projectPlanPSPDFAreas.forEach((annotation) => {
                  if (areAllSelected) {
                    annotation.opacity > 0.4 &&
                      instance.setSelectedAnnotation(annotation.id)
                  } else {
                    annotation.opacity < 0.4 &&
                      instance.setSelectedAnnotation(annotation.id)
                  }
                })
                instance.setSelectedAnnotation(null)
              } else {
                instance.setSelectedAnnotation(null)
                instance.setSelectedAnnotation(annotation.id)
                instance.setSelectedAnnotation(null)
              }
            }}
          >
            <Flex flexDirection='column'>
              <Flex alignItems='center'>
                {projectPlanPSPDFSelectedAreaIds.includes(annotation.id) ||
                (!PSPDFKit && initialSelectedAreas.includes(annotation.id)) ||
                (projectPlanPSPDFAreas?.length ===
                  projectPlanPSPDFSelectedAreaIds.length &&
                  projectPlanPSPDFAreas?.length ===
                    planPermits?.permitStats?.countByArea?.length) ? (
                  <CheckBox
                    sx={{
                      color: annotation.fillColor,
                      opacity: annotation.opacity + 0.3,
                      width: 24,
                      height: 24,
                      ml: '3px',
                      mr: '10px',
                    }}
                  />
                ) : (
                  <Box
                    mr='13px'
                    ml='6px'
                    height={18}
                    width={18}
                    minWidth={18}
                    borderRadius={0.5}
                    sx={{
                      background: annotation.fillColor,
                      opacity: annotation.opacity + 0.3,
                    }}
                  />
                )}

                <Text sx={{ opacity: expanded ? 1 : 0 }}>
                  {annotation.name}
                </Text>
              </Flex>
              <TableSecondaryText
                sx={{
                  opacity: expanded ? 0.6 : 0,
                  ml: '37px',
                  transition: 'height 0.4s ease-out, opacity 0.1s ease-out',
                  height: expanded ? 'auto' : 0,
                }}
              >
                {`[${
                  isSelectAll
                    ? planPermits?.permitStats?.totalElements
                    : planPermits?.permitStats?.getAreaTotalElements(
                        annotation?.id,
                      )
                }] ${localizedStrings.permits}`}
              </TableSecondaryText>
            </Flex>
          </MenuItem>
        )
      })}
    </Box>
  )
}
