import { plainToInstance } from 'class-transformer'
import { Entity } from '@core/entities/entity'
import { ISiteBoxDocumentAnnotationData } from './ISiteBoxDocumentAnnotationData'
import { SiteBoxDocumentAnnotationDataType } from './types'
import { AllShapes, Point, ShapeTypes } from '../skia'

export class SiteBoxDocumentAnnotationData extends Entity<ISiteBoxDocumentAnnotationData> {
  type: SiteBoxDocumentAnnotationDataType
  pageIndex: number
  bbox: number[]
  points: number[]

  public static new(payload: ISiteBoxDocumentAnnotationData) {
    const entity = plainToInstance(SiteBoxDocumentAnnotationData, payload)

    return entity
  }

  public static newFromSkiaShapeData(payload: AllShapes) {
    const getType = (type: ShapeTypes): SiteBoxDocumentAnnotationDataType => {
      switch (type) {
        case 'RECT':
          return 'RECTANGLE'
        case 'POLYGON':
          return 'POLYGON'
        case 'OVAL':
          return 'ELLIPSE'
        default:
          return null
      }
    }

    const findBoundingBox = (
      points: Point[],
    ): [number, number, number, number] => {
      let minX = points[0].x
      let maxX = points[0].x
      let minY = points[0].y
      let maxY = points[0].y

      points.forEach((point) => {
        if (point.x < minX) minX = point.x
        if (point.x > maxX) maxX = point.x
        if (point.y < minY) minY = point.y
        if (point.y > maxY) maxY = point.y
      })

      const width = maxX - minX
      const height = maxY - minY

      // The bottom-left corner is (minX, minY)
      return [minX, minY, width, height]
    }

    const transformedSkiaShapeToSiteBoxDocumentAnnotationData = {
      type: getType(payload.type),
      pageIndex: 0,
      bbox: [],
      points: [],
    }

    if (payload.type === 'POLYGON') {
      const [x, y, width, height] = findBoundingBox(payload.points)

      transformedSkiaShapeToSiteBoxDocumentAnnotationData.points =
        payload.points.map((point) => [point.x, point.y])

      transformedSkiaShapeToSiteBoxDocumentAnnotationData.bbox = [
        x,
        y,
        width,
        height,
      ]
    } else {
      transformedSkiaShapeToSiteBoxDocumentAnnotationData.bbox = [
        payload.x,
        payload.y,
        payload.width,
        payload.height,
      ]
    }

    const entity = plainToInstance(
      SiteBoxDocumentAnnotationData,
      transformedSkiaShapeToSiteBoxDocumentAnnotationData,
    )

    return entity
  }

  public static newFromSkiaShapeDataImage(
    payload: AllShapes,
    {
      imageWidthInPixels,
      pageWidthInPoints,
      pageHeightInPoints,
    }: {
      imageWidthInPixels: number
      pageWidthInPoints: number
      pageHeightInPoints: number
    },
  ) {
    const heightToWidthRation = pageHeightInPoints / pageWidthInPoints
    const imageHeightInPixels = imageWidthInPixels * heightToWidthRation
    const widthPixelsPerPoint = imageWidthInPixels / pageWidthInPoints
    const heightPixelsPerPoint = imageHeightInPixels / pageHeightInPoints

    const getType = (type: ShapeTypes): SiteBoxDocumentAnnotationDataType => {
      switch (type) {
        case 'RECT':
          return 'RECTANGLE'
        case 'POLYGON':
          return 'POLYGON'
        case 'OVAL':
          return 'ELLIPSE'
        default:
          return null
      }
    }

    const findBoundingBox = (
      points: Point[],
    ): [number, number, number, number] => {
      let minX = points[0].x
      let maxX = points[0].x
      let minY = points[0].y
      let maxY = points[0].y

      points.forEach((point) => {
        if (point.x < minX) minX = point.x
        if (point.x > maxX) maxX = point.x
        if (point.y < minY) minY = point.y
        if (point.y > maxY) maxY = point.y
      })

      const width = maxX - minX
      const height = maxY - minY

      // The bottom-left corner is (minX, minY)
      return [minX, minY, width, height]
    }

    const transformedSkiaShapeToSiteBoxDocumentAnnotationData = {
      type: getType(payload.type),
      pageIndex: 0,
      bbox: [],
      points: [],
    }

    if (payload.type === 'POLYGON') {
      const [x, y, width, height] = findBoundingBox(payload.points)

      transformedSkiaShapeToSiteBoxDocumentAnnotationData.points =
        payload.points.map((point) => [
          point.x / widthPixelsPerPoint,
          point.y / heightPixelsPerPoint,
        ])

      transformedSkiaShapeToSiteBoxDocumentAnnotationData.bbox = [
        x / widthPixelsPerPoint,
        y / heightPixelsPerPoint,
        width / widthPixelsPerPoint,
        height / heightPixelsPerPoint,
      ]
    } else {
      transformedSkiaShapeToSiteBoxDocumentAnnotationData.bbox = [
        payload.x / widthPixelsPerPoint,
        payload.y / heightPixelsPerPoint,
        payload.width / widthPixelsPerPoint,
        payload.height / heightPixelsPerPoint,
      ]
    }

    const entity = plainToInstance(
      SiteBoxDocumentAnnotationData,
      transformedSkiaShapeToSiteBoxDocumentAnnotationData,
    )

    return entity
  }
}
