import { localizedStrings } from '@core/strings'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Flex, Text } from '@fundamentals'
import { PermitStatus } from '@core/entities/permit/PermitBase'
import {
  Checkbox,
  DialogTitle,
  IconButton,
  Button,
  Dialog,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useAllOrganisationPermits } from '@core/react-query/features/permits'
import { PermitCard } from '../Cards/PermitCard'
import _xor from 'lodash/xor'
import { PermitBase } from '@core/entities/permit/PermitBase'
import { Filters } from '@elements/Filters'
import { usePermitFilters } from '@common/hooks/usePermitFilters'
import { useGetProject } from '@core/react-query/features/projects/project'

type SelectOrganisationPermitsModalProps = {
  title: string
  triggerComponent: (onClick) => React.ReactNode
  value?: Array<number>
  onChange?: (value: Array<number>, valueObjects: Array<PermitBase>) => void
  organisationId: number
  projectId: number
  filters?: {
    statuses?: Array<PermitStatus>
    templateIds?: Array<number>
    projectIds?: Array<number>
    excludeIds?: Array<number>
  }
  pageSize?: number
  readOnly?: boolean
  multiSelect?: boolean
}

export const SelectOrganisationPermitsModal: React.FC<
  SelectOrganisationPermitsModalProps
> = ({
  value = [],
  onChange,
  title,
  organisationId,
  projectId,
  filters,
  pageSize = 25,
  readOnly,
  multiSelect,
  triggerComponent,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const { data: project } = useGetProject({ projectId })

  const scope = {
    type: 'ORGANISATION' as const,
    organisationId,
  }

  const {
    filters: filterOptions,
    payload,
    queryParamsStore,
  } = usePermitFilters(scope, {
    templateFilterProps: { whitelist: filters?.templateIds, readOnly: true },
    projectFilterProps: { whitelist: filters?.projectIds, readOnly: true },
    statusFilterProps: { whitelist: filters?.statuses, readOnly: true },
  })

  const { data, hasNextPage, fetchNextPage, isLoading } =
    useAllOrganisationPermits(
      {
        organisationId,
        ...payload,
        excludeIds: filters?.excludeIds ?? [],
        // Hide test project permits if the project is not a test project
        hideTestProjects: !project?.isTest,
        size: pageSize,
      },
      { enabled: !!project },
    )

  const numberOfPermits =
    (data?.pages.length - 1) * pageSize + data?.pages.slice(-1)[0].length

  const selectPermit = (permitId: number) => {
    const newValue = multiSelect ? _xor(value, [permitId]) : [permitId]
    const newValueData = newValue.map((id) => {
      for (const page of data.pages) {
        const permit = page.find((p) => p.id === id)
        if (permit) return permit
      }
    })
    onChange(newValue, newValueData)
  }

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <DialogTitle
          variant='h5'
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          sx={{ pr: 1, fontSize: 20, mb: 0 }}
        >
          {title}
          <IconButton aria-label='close-modal' onClick={() => setIsOpen(false)}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <Box px={2} mt={2}>
          <Filters filters={filterOptions} store={queryParamsStore} isInModal />
        </Box>

        <Box
          id='scrollableContainer'
          overflow='auto'
          p={2}
          pt={0}
          height='800px'
        >
          {!isLoading && (
            <InfiniteScroll
              dataLength={numberOfPermits}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                <Text mt={1.5} textAlign='center' color='grey'>
                  {localizedStrings.loading}
                </Text>
              }
              endMessage={
                <Text mt={1.5} textAlign='center' color='grey'>
                  {localizedStrings.noMoreResults}
                </Text>
              }
              scrollableTarget='scrollableContainer'
            >
              {data?.pages.flatMap((page) => {
                return page.map(({ id, ...permit }) => {
                  const isSelected = value.includes(id)
                  return (
                    <PermitCard
                      key={id}
                      templateName={permit.template.name}
                      sx={{
                        borderColor: isSelected ? 'primary.main' : null,
                        '&:hover': { borderColor: 'primary.light' },
                        mt: 2,
                      }}
                      onClick={() => selectPermit(id)}
                      icon={
                        <Checkbox
                          checked={isSelected}
                          onChange={() => selectPermit(id)}
                          disabled={readOnly}
                        />
                      }
                      {...permit}
                    />
                  )
                })
              })}
            </InfiniteScroll>
          )}
          <Flex width='100%' justifyContent='flex-end' mt={3}>
            <Box
              position='absolute'
              bottom={0}
              width='100%'
              bgcolor='white'
              py={2}
            >
              <Button
                variant='contained'
                sx={{ float: 'right', zIndex: 10 }}
                onClick={(e) => setIsOpen(false)}
              >
                {localizedStrings.done}
              </Button>
            </Box>
          </Flex>
        </Box>
      </Dialog>
      {triggerComponent(() => setIsOpen(true))}
    </>
  )
}
