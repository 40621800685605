import { localizedStrings } from '@core/strings'
import React, { useImperativeHandle, useMemo, useRef, useState } from 'react'
import { useModal } from '@common/GlobalModal'
import { DialogContent, Button, Card } from '@common/material'
import { Box, Flex, Text } from '@fundamentals'
import { useGetProjectPlan } from '@core/react-query/features/projects/plans'
import { useNavigationContext } from '@common/hooks'
import { SaveSelectedProjectPlanZonesModal } from './SaveSelectedProjectPlanZonesModal'
import { useGetPlanPermits } from '@core/react-query/features/permits/plans'
import { usePermitTemplatesFilter } from '@core/providers/filters/usePermitTemplatesFilter'
import { useTextInputFilter } from '@core/providers/filters'
import { Filters } from 'webapp/components/elements/Filters/Filters'
import { useDateRangeFilter } from '@core/providers/filters'
import { useFilterValueStore } from '@core/providers/filters'
import { ProjectPlanPermitsSidebar } from '@modules/projectPlan/components/ProjectPlanPermitsSidebar'
import { usePermitStatusFilter } from '@core/providers/filters/usePermitStatusFilter'
import { ModalDialogContainer } from '@common/modal'
import { ProjectPlanZonesSidebar } from '@modules/projectPlan/components/ProjectPlanZonesSidebar'
import { GroupMetadata, ShapeGroup, CanvasState } from '@core/entities/skia'
import { ProjectPlanArea } from '@core/entities/project/ProjectPlanArea'
import dynamic from 'next/dynamic'
import { ProjectPlan } from '@core/entities/project/ProjectPlan'
import { PROJECT_PLAN_AREA_COLORS } from '@core/entities/project'
import produce from 'immer'

const SkiaPlanView = dynamic(() =>
  import('@modules/skia/SkiaPlanView').then((mod) => mod.SkiaPlanView),
)

interface ComponentType {
  nodeId: string
  projectId: number
  planId: number
  nodePath: string
  initialSelectedAreas?: string[]
  permitId?: number
  readOnly?: boolean
  onSuccess?: (selectedAreaIds: string[]) => void
  plan?: ProjectPlan
}

export const ProjectPlanPermitsModal: React.FC<ComponentType> = ({
  projectId,
  nodeId,
  planId,
  nodePath,
  initialSelectedAreas = [],
  permitId,
  readOnly = false,
  onSuccess,
  plan,
}) => {
  const { organisationId } = useNavigationContext()
  const [canvasState, setCanvasState] = useState<CanvasState>({
    mode: 'VIEW',
    selectedShapeIds: initialSelectedAreas,
  })
  const [shapeGroups, setShapeGroups] = useState<ShapeGroup[]>([])
  const [groupMetadata, setGroupMetadata] = useState<GroupMetadata[]>([])

  const skiaDiagramRef = useRef(null)

  useImperativeHandle(skiaDiagramRef, () => ({
    selectGroups: (
      groupId: string,
      selectedGroups: string[],
      mode: CanvasState['mode'],
    ) => {
      setCanvasState(
        produce((draft) => {
          draft.groupId = groupId
          if (mode === 'VIEW') {
            draft.selectedShapeIds = selectedGroups
          } else if (mode === 'EDIT') {
            draft.selectedShapeIds = selectedGroups
          }
        }),
      )
    },
  }))

  const { hideModal, showModal } = useModal()
  const { data: projectPlan } = useGetProjectPlan(
    {
      projectId,
      nodeId,
      planId,
    },
    {
      refetchOnMount: !plan,
    },
  )

  const selectedShapes =
    (canvasState.mode === 'VIEW' && canvasState?.selectedShapeIds) ?? []

  const filters = {
    statuses: usePermitStatusFilter({
      multiple: true,
    }),
    dateRange: useDateRangeFilter({
      label: localizedStrings.dateRange,
      dataTest: 'date-range-filter',
    }),
    templateIds: usePermitTemplatesFilter({
      label: localizedStrings.permitTemplate,
      scope: {
        type: 'PROJECT',
        projectId,
        organisationId,
      },
      multiple: true,
    }),
    permitUUID: useTextInputFilter({
      label: localizedStrings.uniqueCode,
    }),
  }

  const store = useFilterValueStore({
    filters,
    initialValues: {
      statuses: [
        'OPEN',
        'REQUESTED',
        'ISSUED',
        'EXTENSION_REQUESTED',
        'SENT_TO_PERMIT_HOLDER',
      ],
    },
  })

  const { dateRange, statuses, ...restFilters } = store.values

  const filterQueries = {
    ...(statuses?.length && { statuses }),
    ...(dateRange?.startTime && { startTime: dateRange?.startTime }),
    ...(dateRange?.endTime && { endTime: dateRange?.endTime }),
    ...restFilters,
  }

  const { data: planPermits } = useGetPlanPermits(
    {
      planId,
      projectId,
      ...filterQueries,
    },
    {
      onSuccess(planPermitsData) {
        const { shapeGroups, groupMetadata } = projectPlan.areas.reduce(
          (acc, area) => {
            const shape = area.getPlanAreaShape({
              imageWidthInPixels: projectPlan.imageWidthInPixels,
              pageWidthInPoints: projectPlan.pdfWidthInPoints,
              pageHeightInPoints: projectPlan.pdfHeightInPoints,
            })

            acc.shapeGroups.push({ shapes: [shape], groupId: area.uuid })
            const areaColor =
              PROJECT_PLAN_AREA_COLORS.find(
                (areaColor) => areaColor.enum === area?.color,
              )?.hex || area?.color

            acc.groupMetadata.push({
              color: areaColor,
              groupId: area.uuid,
              groupName: area.name,
              description: `${planPermitsData.permitStats?.getAreaTotalElements(
                shape?.id,
              )} ${localizedStrings.permits}`,
            })
            return acc
          },
          { shapeGroups: [], groupMetadata: [] },
        )
        setShapeGroups(shapeGroups)
        setGroupMetadata(groupMetadata)
      },
      enabled: !!projectPlan || !!plan,
    },
  )

  const allSelectedPermits =
    planPermits?.getSelectedPermits(selectedShapes) ?? []

  const filteredPermitsMemoised = useMemo(() => {
    const withoutCurrentPermits = allSelectedPermits.filter(
      (permit) => permit.id !== permitId,
    )

    return withoutCurrentPermits
  }, [JSON.stringify(allSelectedPermits)])

  const currentPermit = planPermits?.getPermit(permitId)

  const newSelectedShapes = shapeGroups
    .filter((shapeGroup) => selectedShapes.includes(shapeGroup.groupId))
    .map((shapeGroup) => {
      const groupMeta = groupMetadata.find(
        (group) => group.groupId === shapeGroup.groupId,
      )

      const shape = shapeGroup.shapes[0]
      return ProjectPlanArea.newFromSkiaShape(
        shape,
        groupMeta?.color,
        groupMeta?.groupName,
        {
          imageWidthInPixels: projectPlan.imageWidthInPixels,
          pageWidthInPoints: projectPlan.pdfWidthInPoints,
          pageHeightInPoints: projectPlan.pdfHeightInPoints,
        },
      )
    })

  return (
    <ModalDialogContainer fullScreen>
      <Card sx={{ p: 2, borderRadius: 0, width: '100%', minHeight: '4.4rem' }}>
        <Flex alignItems='center' justifyContent='space-between'>
          <Text variant='h5'>{nodePath}</Text>

          <Box>
            <Button variant='outlined' onClick={() => hideModal()}>
              {readOnly ? localizedStrings.close : localizedStrings.cancel}
            </Button>
            {!readOnly && (
              <Button
                variant='contained'
                sx={{ ml: 1 }}
                onClick={() => {
                  showModal(
                    <SaveSelectedProjectPlanZonesModal
                      newAreas={newSelectedShapes}
                      onSuccess={() => {
                        hideModal()
                        onSuccess && onSuccess(selectedShapes)
                      }}
                    />,
                  )
                }}
                data-test='close-permit-submit'
              >
                {localizedStrings.save}
              </Button>
            )}
          </Box>
        </Flex>
      </Card>
      <DialogContent sx={{ p: 0 }}>
        <Box mt={1} mx={1}>
          <Filters filters={filters} store={store} />
        </Box>

        <Flex flexDirection='column' height='calc(100vh - 125px)'>
          <Flex
            flex={1}
            height='100%'
            width='100%'
            justifyContent='space-between'
          >
            <ProjectPlanZonesSidebar
              planPermits={planPermits}
              planAreas={projectPlan?.areas ?? []}
              canvasState={canvasState}
              shapeGroups={shapeGroups}
              skiaDiagramRef={skiaDiagramRef}
              groupMetadata={groupMetadata}
              readOnly={readOnly}
              multipleSelection
            />

            <SkiaPlanView
              imageUrl={projectPlan?.locationPlanImageDownloadUrl}
              state={canvasState}
              setState={(canvasState) => {
                setCanvasState(canvasState)
              }}
              shapeGroups={shapeGroups}
              groupMetadata={groupMetadata}
              multipleSelection
              readOnly={readOnly}
            />

            <ProjectPlanPermitsSidebar
              organisationId={organisationId}
              permitId={permitId}
              currentPermit={currentPermit}
              filteredPermits={filteredPermitsMemoised}
            />
          </Flex>
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}
