import { IOrganisationUser, OrganisationRole, User } from '@core/entities/user'
import { plainToInstance } from 'class-transformer'
import moment from 'moment'
import { UserStatus } from '@core/entities/user/UserStatus'
import { localizedStrings } from '@core/strings'

export class OrganisationUser extends User<IOrganisationUser> {
  roles: OrganisationRole[]
  status: UserStatus
  lastActive?: string

  public static new(payload: unknown): OrganisationUser {
    const entity = plainToInstance(OrganisationUser, payload)

    return entity
  }
  public static roleString(role: OrganisationRole) {
    switch (role) {
      case 'USER':
        return localizedStrings.user
      case 'ACCOUNT_OWNER':
        return localizedStrings.accountOwner
      case 'ACCOUNT_VIEWER':
        return localizedStrings.accountViewer
      case 'DEVELOPER':
        return localizedStrings.developer
      default:
        return role
    }
  }

  public getLastActiveString(): string {
    return this?.lastActive
      ? moment(this?.lastActive).format('Do MMM YYYY')
      : localizedStrings.never
  }

  public getStatusString(): string {
    switch (this.status) {
      case 'ACTIVE':
        return localizedStrings.active
      case 'DISABLED':
        return localizedStrings.disabled
      default:
        return this.status
    }
  }
}
