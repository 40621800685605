import { useInterpret } from '@xstate/react'
import {
  ProjectPlanMachineContext,
  projectPlanMachine,
} from './projectPlanMachine'
import { useGetProjectPlan } from '@core/react-query/features/projects/plans'
import {
  PROJECT_PLAN_AREA_COLORS,
  ProjectPlanPSPDFArea,
} from '@core/entities/project/ProjectPlanPSPDFArea'
import _values from 'lodash/values'
import { Annotation, Instance, List } from 'pspdfkit'
import { ProjectPlanArea } from '@core/entities/project/ProjectPlanArea'

export const useProjectPlanMachine = ({
  pspdfkitRef,
  projectId,
  nodeId,
  planId,
  projectPlanMode,
  initialProjectPlanAreas,
  colorOpacity,
  useImage,
}) => {
  const {
    data,
    isStale,
    refetch: refetchGetProjectPlan,
  } = useGetProjectPlan(
    {
      projectId,
      planId,
      nodeId,
    },
    { enabled: false },
  )

  const getColorRGB = (annotationSize: number) =>
    PROJECT_PLAN_AREA_COLORS[
      annotationSize -
        ~~(annotationSize / PROJECT_PLAN_AREA_COLORS.length) *
          PROJECT_PLAN_AREA_COLORS.length
    ]?.rgb

  return useInterpret(projectPlanMachine, {
    devTools: true,
    context: {
      projectPlanMode,
    },
    services: {
      performGetProjectPlanConfig: async () => {
        if (data) return data
        const response = await refetchGetProjectPlan({ throwOnError: true })

        return response.data
      },

      performInitializingInstance: async (ctx: ProjectPlanMachineContext) => {
        const PSPDFKit: any = await import('pspdfkit')

        const { projectPlan } = ctx

        const projectPlanPSPDFAreas = projectPlan?.areas?.map((area) =>
          ProjectPlanPSPDFArea.newFromProjectPlanArea(
            area.toPlain(),
            colorOpacity.default,
          ),
        )

        initialProjectPlanAreas?.forEach((initialProjectPlanArea) => {
          projectPlanPSPDFAreas?.forEach((area) => {
            if (initialProjectPlanArea.includes(area.id)) {
              area.selectArea()
            }
          })
        })

        const projectPlanAreas = projectPlan?.areas.map((annotation) => {
          return ProjectPlanArea.new(annotation)
        })

        const licenseKey = Project.pspdfkitLicenseKey

        let url = ctx.projectPlan.locationPlanDocumentDownloadUrl
        if (useImage) {
          url = ctx.projectPlan.locationPlanImageDownloadUrl
        }

        const instance: Instance = await PSPDFKit.load({
          licenseKey,
          container: pspdfkitRef.current,
          document: url,
          baseUrl: `${window.location.protocol}//${window.location.host}/`,
          autoSaveMode: PSPDFKit.AutoSaveMode.DISABLED,
          instantJSON: {
            annotations: projectPlanPSPDFAreas.map((area) => {
              if (useImage) {
                return {
                  ...area
                    .toImageAnnotation({
                      pageHeightInPoints: ctx.projectPlan.pdfHeightInPoints,
                      pageWidthInPoints: ctx.projectPlan.pdfWidthInPoints,
                      imageWidthInPixels: ctx.projectPlan.imageWidthInPixels,
                    })
                    .toPlain(),
                  customData: { isFromSiteAssist: true },
                }
              }
              return {
                ...area.toPlain(),
                customData: { isFromSiteAssist: true },
              }
            }),
            format: 'https://pspdfkit.com/instant-json/v1',
          },
        })

        await instance.history.enable()
        const viewState = instance.viewState
        await instance.setViewState(viewState.set('showToolbar', false))

        await instance.setViewState(
          instance.viewState.set('enableAnnotationToolbar', false),
        )

        const annotations = await instance.getAnnotations(0)

        annotations.forEach((annotation) => {
          if (!annotation.customData?.isFromSiteAssist)
            instance.delete(annotation)
        })

        const drawnAnnotations = annotations.filter(
          (annotation) =>
            annotation.boundingBox.width > 0 &&
            annotation.boundingBox.height > 0,
        )

        instance.setAnnotationPresets((presets) => {
          _values(presets).forEach((preset) => {
            preset.strokeColor = new PSPDFKit.Color(
              getColorRGB(drawnAnnotations.size),
            )
            preset.fillColor = new PSPDFKit.Color(
              getColorRGB(drawnAnnotations.size),
            )
          })
          return presets
        })

        return {
          PSPDFKit,
          instance,
          projectPlanPSPDFAreas,
          requestAreas: projectPlanAreas,
          colorOpacity,
        }
      },

      performSetAreasCreateZones: async (
        ctx: ProjectPlanMachineContext,
        event,
      ) => {
        const { PSPDFKit, instance } = ctx
        const annotations = await instance.getAnnotations(0)

        const drawnAnnotations = annotations.filter(
          (annotation) =>
            annotation.boundingBox.width > 0 &&
            annotation.boundingBox.height > 0,
        )

        instance.setAnnotationPresets((presets) => {
          _values(presets).forEach((preset) => {
            preset.strokeColor = new PSPDFKit.Color(
              getColorRGB(drawnAnnotations.size),
            )
            preset.fillColor = new PSPDFKit.Color(
              getColorRGB(drawnAnnotations.size),
            )
          })
          return presets
        })

        const projectPlanAreas = annotations
          .map((annotation) => {
            const pspdfKitAnnotation = ProjectPlanPSPDFArea.new(
              PSPDFKit.Annotations.toSerializableObject(annotation),
            )
            return ProjectPlanArea.newFromInstantJSON(pspdfKitAnnotation)
          })
          .toArray()

        const projectPlanPSPDFAreas = annotations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((annotation) =>
            ProjectPlanPSPDFArea.new({
              ...PSPDFKit.Annotations.toSerializableObject(annotation),
              /* !!! HACK ALERT: selection is not implemented by the state machine.
               * It is using the pspdfkit library to decide what is selected and what is not,
               * because of pspdfkit web library limitations. */
              isSelected: annotation.opacity === colorOpacity.selected,
            }),
          )
          .toArray()

        return { requestAreas: projectPlanAreas, projectPlanPSPDFAreas }
      },

      performSetAreasViewZones: async (
        ctx: ProjectPlanMachineContext,
        event,
      ) => {
        const { PSPDFKit, instance } = ctx
        const annotations = await instance.getAnnotations(0)

        const projectPlanAreas = annotations
          .map((annotation) => {
            const pspdfKitAnnotation = ProjectPlanPSPDFArea.new(
              PSPDFKit.Annotations.toSerializableObject(annotation),
            )
            return ProjectPlanArea.newFromInstantJSON(pspdfKitAnnotation)
          })
          .toArray()

        const projectPlanPSPDFAreas = annotations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((annotation) =>
            ProjectPlanPSPDFArea.new({
              ...PSPDFKit.Annotations.toSerializableObject(annotation),
              /* !!! HACK ALERT: selection is not implemented by the state machine.
               * It is using the pspdfkit library to decide what is selected and what is not,
               * because of pspdfkit web library limitations. */
              isSelected: annotation.opacity === colorOpacity.selected,
            }),
          )
          .toArray()

        return { requestAreas: projectPlanAreas, projectPlanPSPDFAreas }
      },

      performSetAreasChooseZonesWithPermits: async (
        ctx: ProjectPlanMachineContext,
        event,
      ) => {
        const { PSPDFKit, instance } = ctx
        const annotations: List<Annotation> = await instance.getAnnotations(0)

        const projectPlanAreas = annotations
          .map((annotation) => {
            const pspdfKitAnnotation = ProjectPlanPSPDFArea.new(
              PSPDFKit.Annotations.toSerializableObject(annotation),
            )
            return ProjectPlanArea.newFromInstantJSON(pspdfKitAnnotation)
          })
          .toArray()

        const projectPlanPSPDFAreas = annotations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((annotation) =>
            ProjectPlanPSPDFArea.new({
              ...PSPDFKit.Annotations.toSerializableObject(annotation),
              /* !!! HACK ALERT: selection is not implemented by the state machine.
               * It is using the pspdfkit library to decide what is selected and what is not,
               * because of pspdfkit web library limitations. */
              isSelected: annotation.opacity < 0.4 ? false : true,
            }),
          )
          .toArray()

        const projectPlanPSPDFSelectedAreas = projectPlanPSPDFAreas
          .filter((area) => area.isSelected)
          .map((area) => area.id)

        annotations.forEach((annotation) => {
          if (
            projectPlanPSPDFSelectedAreas.length === 0 &&
            initialProjectPlanAreas.length === 0
          ) {
            const updated = annotation.set('opacity', 0.3).set('pageIndex', 0)
            instance.update(updated)
          } else if (!projectPlanPSPDFSelectedAreas.includes(annotation.id)) {
            const updated = annotation.set('opacity', 0.2).set('pageIndex', 0)
            instance.update(updated)
          }
        })

        return {
          requestAreas: projectPlanAreas,
          projectPlanPSPDFAreas,
        }
      },

      performSetAreasViewZonesWithPermits: async (
        ctx: ProjectPlanMachineContext,
        event,
      ) => {
        const { PSPDFKit, instance } = ctx
        const annotations: List<Annotation> = await instance.getAnnotations(0)

        const projectPlanAreas = annotations
          .map((annotation) => {
            const pspdfKitAnnotation = ProjectPlanPSPDFArea.new(
              PSPDFKit.Annotations.toSerializableObject(annotation),
            )
            return ProjectPlanArea.newFromInstantJSON(pspdfKitAnnotation)
          })
          .toArray()

        const projectPlanPSPDFAreas = annotations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((annotation) =>
            ProjectPlanPSPDFArea.new({
              ...PSPDFKit.Annotations.toSerializableObject(annotation),
              /* !!! HACK ALERT: selection is not implemented by the state machine.
               * It is using the pspdfkit library to decide what is selected and what is not,
               * because of pspdfkit web library limitations. */
              isSelected: annotation.opacity < 0.4 ? false : true,
            }),
          )
          .toArray()

        const projectPlanPSPDFSelectedAreas = projectPlanPSPDFAreas
          .filter((area) => area.isSelected)
          .map((area) => area.id)

        annotations.forEach((annotation) => {
          if (
            projectPlanPSPDFSelectedAreas.length === 0 &&
            initialProjectPlanAreas.length === 0
          ) {
            const updated = annotation.set('opacity', 0.3).set('pageIndex', 0)
            instance.update(updated)
          } else if (!projectPlanPSPDFSelectedAreas.includes(annotation.id)) {
            const updated = annotation.set('opacity', 0.2).set('pageIndex', 0)
            instance.update(updated)
          }
        })

        return {
          requestAreas: projectPlanAreas,
          projectPlanPSPDFAreas,
        }
      },
    },
  })
}
